<template>
  <div id="app">
    <div class="main-wrapper with-alert">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
// import NavBar from '@/components/NavBar'
import { isNil } from 'lodash'

export default {
  // components: { NavBar },

  watch: {
    $route: {
      handler($route) {
        if (!isNil($route)) {
          if ($route.meta.lang === 'en') {
            this.$i18n.locale = 'en'
          } else {
            this.$i18n.locale = 'fr'
          }
        }
      },
      immediate: true,
    },
  },

  mounted() {
    const url = window.location.href
    if (url.includes('/fr')) {
      window.location.href = url.replace('/fr', '')
    }
    
    window.addEventListener(
      'scroll',
      () => {
        const top = window.scrollY
        const topNav = document.querySelector('.header-ctas')

        if (top >= topNav.offsetHeight) {
          document.querySelector('.main-wrapper').classList.add('nav-is-fixed')
          return
        }

        document.querySelector('.main-wrapper').classList.remove('nav-is-fixed')
      },
      false,
    )
  },
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';

@import '@/theme/flickity.scss';

@import '@/theme/base.scss';
@import '@/theme/typography.scss';
@import '@/theme/btn.scss';

@import '@/theme/site-modules.scss';

@import '@/theme/animation-layer.scss';

@import '@/theme/glightbox.scss';

.flickity-button {
  background: transparent;

  &:hover {
    background: transparent;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.555s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
  transform: scale(1.0125) rotate(0.525deg);
}
</style>
