import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import Home from '@/views/Home'
import Promotion from '@/views/Promotion'
import Soumission from '@/views/Soumission'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE,
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        lang: 'fr',
      },
    },
    {
      path: '/en',
      name: 'home-en',
      component: Home,
      meta: {
        lang: 'en',
      },
    },
    {
      path: '/promotion',
      name: 'promotion',
      component: Promotion,
      meta: {
        lang: 'fr',
      },
    },
    {
      path: '/en/promotion',
      name: 'promotion-en',
      component: Promotion,
      meta: {
        lang: 'en',
      },
    },
    {
      path: '/demande-de-soumission',
      name: 'soumission',
      component: Soumission,
      meta: {
        lang: 'fr',
      },
    },
    {
      path: '/en/request-for-proposal',
      name: 'soumission-en',
      component: Soumission,
      meta: {
        lang: 'en',
      },
    },
    { path: '*', redirect: '/' },
  ],
})

export default router
