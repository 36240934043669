<template>
  <svg width="62" height="57" viewBox="0 0 62 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M54.9 5.4H32.6V1.8C32.6 0.8 31.8 0 30.8 0C29.8 0 29 0.8 29 1.8V5.3H6.7C3 5.3 0 8.3 0 12V31.2C0 34.9 3 37.9 6.7 37.9H15.7L6.8 53.7C6.3 54.5 6.6 55.6 7.5 56.1C7.8 56.2 8 56.3 8.3 56.3C8.9 56.3 9.5 56 9.8 55.4L19.6 37.8H29V54.5C29 55.5 29.8 56.3 30.8 56.3C31.8 56.3 32.6 55.5 32.6 54.5V37.9H42.1L52 55.5C52.3 56.1 52.9 56.4 53.5 56.4C53.8 56.4 54.1 56.3 54.4 56.2C55.2 55.7 55.5 54.7 55.1 53.8L46.2 38H55C58.7 38 61.7 35 61.7 31.3V12C61.6 8.3 58.6 5.4 54.9 5.4ZM58.1 31.2C58.1 32.9 56.7 34.4 54.9 34.4H6.7C5 34.4 3.5 33 3.5 31.2V12C3.5 10.3 4.9 8.8 6.7 8.8H54.9C56.6 8.8 58.1 10.2 58.1 12V31.2V31.2Z"
      fill="#2C444D"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgConference',
}
</script>
