<template>
  <div class="page-wrapper">
    <!-- Alert Banner -->
    <!-- <AlertBanner /> -->

    <div class="mobile-phone-bar">
      <span class="small-text hours">
        <p><strong>{{ $t('open_note') }}</strong><br />{{ $t('open_hours') }}</p>
      </span>
      <a href="tel:18662146940" class="tel">1-866-214-6940</a>
    </div>

    <!-- Header ctas -->
    <Header />

    <!-- Hero -->
    <section class="hero">
      <div class="carousel-wrapper">
        <div class="carousel-cell">
          <img src="@/assets/img/hero-ete-2.webp" alt="Ambiance été au Mont-Tremblant" width="1512" height="655">
        </div>
        <div class="carousel-cell">
          <img data-flickity-lazyload="/img/hero-automne-2.webp" alt="Ambiance automne au Mont-Tremblant" width="1512" height="655">
        </div>
        <div class="carousel-cell">
          <img data-flickity-lazyload="/img/hero-hiver-2-new.webp" alt="Ambiance hiver au Mont-Tremblant" width="1512" height="655">
        </div>
        <div class="carousel-cell">
          <img data-flickity-lazyload="/img/hero-printemps.webp" alt="Ambiance printemps au Mont-Tremblant" width="1512" height="655">
        </div>
      </div>
      <h1 class="big-title site-max-width" data-inview="fadeInUp" data-delay="100" v-html="$t('hero_title')">
      </h1>

      <a href="https://youtu.be/zWxXs86mg4Y" class="glightbox" v-if="$route.meta.lang === 'fr'">
        <svg class="play-btn" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30" r="30" fill="#F4F5F7"/>
          <path d="M39.9482 28.6605L27.2205 20.2727C26.1347 19.5565 24.6665 20.3361 24.6665 21.6211V38.3752C24.6665 39.6817 26.1347 40.4407 27.2205 39.7237L39.9482 31.3359C40.9059 30.725 40.9059 29.2926 39.9482 28.6605V28.6605Z" fill="#2C444D"/>
        </svg>
      </a>

      <a href="https://youtu.be/zT1gjk0a6mQ" class="glightbox" v-if="$route.meta.lang === 'en'">
        <svg class="play-btn" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30" r="30" fill="#F4F5F7"/>
          <path d="M39.9482 28.6605L27.2205 20.2727C26.1347 19.5565 24.6665 20.3361 24.6665 21.6211V38.3752C24.6665 39.6817 26.1347 40.4407 27.2205 39.7237L39.9482 31.3359C40.9059 30.725 40.9059 29.2926 39.9482 28.6605V28.6605Z" fill="#2C444D"/>
        </svg>
      </a>
    </section>

    <!-- Site Nav -->
    <NavBar />

    <section class="experience_slider site-max-width" id="experience">
      <div class="title_wrap">
        <h2 class="big-title" data-inview="fadeInUp" data-delay="200" v-html="$t('exp_title')"></h2>
        <p class="medium-text" data-inview="fadeInUp" data-delay="300">{{ $t('exp_desc') }}</p>
        <div data-inview="fadeInUp" data-delay="400"><router-link :to="{ name: $route.meta.lang === 'fr' ? 'soumission' : 'soumission-en' }" class="btn cta" title="Demandez une soumission" aria-label="Lien vers la page du formulaire de soumission">{{ $t('cta') }}</router-link></div>
      </div>
      <div class="experience-carousel exp-carousel">
        <div class="carousel-cell">
          <div class="content">
            <svg viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.8666 18.8682C29.4913 28.5978 33.8807 34.9124 41.383 29.5538L41.2622 29.2686C34.929 31.0828 28.9563 19.5859 25.0375 12.9173L17.4734 0L15.1733 4.0376C15.1733 4.0376 22.1625 15.9148 23.8666 18.8682Z"
                fill="#FEFEFE"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.8417 6.36256L0 30.8141L2.31275 30.8251L12.7135 12.3962L20.204 25.177C25.3355 33.8858 29.8865 41.7116 37.7505 35.9205L37.6306 35.6298C31.1964 37.6148 24.9576 25.3148 21.4058 19.2826L13.8417 6.36256Z"
                fill="#FEFEFE"
              />
            </svg>
            <p class="regular-title">{{ $t('exp_text_1') }}</p>
          </div>
          <img class="desktop" data-flickity-lazyload="/img/slider-exp-1.webp" alt="Groupes Tremblant" width="1315" height="587" />
          <img class="mobile" data-flickity-lazyload="/img/slider-exp-mobile-1.webp" alt="Groupes Tremblant" width="990" height="768" />
        </div>
        <div class="carousel-cell">
          <div class="content">
            <svg viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.8666 18.8682C29.4913 28.5978 33.8807 34.9124 41.383 29.5538L41.2622 29.2686C34.929 31.0828 28.9563 19.5859 25.0375 12.9173L17.4734 0L15.1733 4.0376C15.1733 4.0376 22.1625 15.9148 23.8666 18.8682Z"
                fill="#FEFEFE"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.8417 6.36256L0 30.8141L2.31275 30.8251L12.7135 12.3962L20.204 25.177C25.3355 33.8858 29.8865 41.7116 37.7505 35.9205L37.6306 35.6298C31.1964 37.6148 24.9576 25.3148 21.4058 19.2826L13.8417 6.36256Z"
                fill="#FEFEFE"
              />
            </svg>
            <p class="regular-title">{{ $t('exp_text_2') }}</p>
          </div>
          <img class="desktop" data-flickity-lazyload="/img/slider-exp-2-new.webp" alt="Groupes Tremblant" width="1315" height="587" />
          <img class="mobile" data-flickity-lazyload="/img/slider-exp-mobile-2-new.webp" alt="Groupes Tremblant" width="990" height="768" />
        </div>
        <div class="carousel-cell">
          <div class="content">
            <svg viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.8666 18.8682C29.4913 28.5978 33.8807 34.9124 41.383 29.5538L41.2622 29.2686C34.929 31.0828 28.9563 19.5859 25.0375 12.9173L17.4734 0L15.1733 4.0376C15.1733 4.0376 22.1625 15.9148 23.8666 18.8682Z"
                fill="#FEFEFE"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.8417 6.36256L0 30.8141L2.31275 30.8251L12.7135 12.3962L20.204 25.177C25.3355 33.8858 29.8865 41.7116 37.7505 35.9205L37.6306 35.6298C31.1964 37.6148 24.9576 25.3148 21.4058 19.2826L13.8417 6.36256Z"
                fill="#FEFEFE"
              />
            </svg>
            <p class="regular-title">{{ $t('exp_text_3') }}</p>
          </div>
          <img class="desktop" data-flickity-lazyload="/img/slider-exp-3.webp" alt="Groupes Tremblant" width="1315" height="587" />
          <img class="mobile" data-flickity-lazyload="/img/slider-exp-mobile-3.webp" alt="Groupes Tremblant" width="990" height="768" />
        </div>
        <div class="carousel-cell">
          <div class="content">
            <svg viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.8666 18.8682C29.4913 28.5978 33.8807 34.9124 41.383 29.5538L41.2622 29.2686C34.929 31.0828 28.9563 19.5859 25.0375 12.9173L17.4734 0L15.1733 4.0376C15.1733 4.0376 22.1625 15.9148 23.8666 18.8682Z"
                fill="#FEFEFE"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.8417 6.36256L0 30.8141L2.31275 30.8251L12.7135 12.3962L20.204 25.177C25.3355 33.8858 29.8865 41.7116 37.7505 35.9205L37.6306 35.6298C31.1964 37.6148 24.9576 25.3148 21.4058 19.2826L13.8417 6.36256Z"
                fill="#FEFEFE"
              />
            </svg>
            <p class="regular-title">{{ $t('exp_text_4') }}</p>
          </div>
          <img class="desktop" data-flickity-lazyload="/img/slider-exp-4.webp" alt="Groupes Tremblant" width="1315" height="587" />
          <img class="mobile" data-flickity-lazyload="/img/slider-exp-mobile-4.webp" alt="Groupes Tremblant" width="990" height="768" />
        </div>
        <div class="carousel-cell">
          <div class="content">
            <svg viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.8666 18.8682C29.4913 28.5978 33.8807 34.9124 41.383 29.5538L41.2622 29.2686C34.929 31.0828 28.9563 19.5859 25.0375 12.9173L17.4734 0L15.1733 4.0376C15.1733 4.0376 22.1625 15.9148 23.8666 18.8682Z"
                fill="#FEFEFE"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.8417 6.36256L0 30.8141L2.31275 30.8251L12.7135 12.3962L20.204 25.177C25.3355 33.8858 29.8865 41.7116 37.7505 35.9205L37.6306 35.6298C31.1964 37.6148 24.9576 25.3148 21.4058 19.2826L13.8417 6.36256Z"
                fill="#FEFEFE"
              />
            </svg>
            <p class="regular-title">{{ $t('exp_text_5') }}</p>
          </div>
          <img class="desktop" data-flickity-lazyload="/img/slider-exp-5.webp" alt="Groupes Tremblant" width="1315" height="587" />
          <img class="mobile" data-flickity-lazyload="/img/slider-exp-mobile-5.webp" alt="Groupes Tremblant" width="990" height="768" />
        </div>
        <div class="carousel-cell">
          <div class="content">
            <svg viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.8666 18.8682C29.4913 28.5978 33.8807 34.9124 41.383 29.5538L41.2622 29.2686C34.929 31.0828 28.9563 19.5859 25.0375 12.9173L17.4734 0L15.1733 4.0376C15.1733 4.0376 22.1625 15.9148 23.8666 18.8682Z"
                fill="#FEFEFE"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.8417 6.36256L0 30.8141L2.31275 30.8251L12.7135 12.3962L20.204 25.177C25.3355 33.8858 29.8865 41.7116 37.7505 35.9205L37.6306 35.6298C31.1964 37.6148 24.9576 25.3148 21.4058 19.2826L13.8417 6.36256Z"
                fill="#FEFEFE"
              />
            </svg>
            <p class="regular-title">{{ $t('exp_text_6') }}</p>
          </div>
          <img class="desktop" data-flickity-lazyload="/img/slider-exp-6.webp" alt="Groupes Tremblant" width="1315" height="587" />
          <img class="mobile" data-flickity-lazyload="/img/slider-exp-mobile-6.webp" alt="Groupes Tremblant" width="990" height="768" />
        </div>
      </div>
    </section>

    <!-- FullSlider 
    <section class="fullslider site-max-width" id="experience">
      <FullSlider />
    </section>-->

    <!-- Lodging -->
    <section class="lodging site-max-width">
      <div class="inner">
        <div class="left">
          <h2 class="big-title" data-inview="fadeInUp" data-delay="100">{{ $t('heb_title') }}</h2>
          <p class="medium-text" data-inview="fadeInUp" data-delay="300">{{ $t('heb_desc') }}</p>
          <div data-inview="fadeInUp" data-delay="400"><router-link :to="{ name: $route.meta.lang === 'fr' ? 'soumission' : 'soumission-en' }" class="btn cta" title="Demandez une soumission" aria-label="Lien vers la page du formulaire de soumission">{{ $t('cta') }}</router-link></div>
        </div>

        <div class="right">
          <div class="imgs imgs-left">
            <div class="scroller scroller-left">
              <img src="@/assets/img/img-lodging1.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging2.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging3.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging4.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging5.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging6.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging1.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging2.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging3.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging4.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging5.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging6.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging1.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging2.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging3.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging4.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging5.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging6.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
            </div>
          </div>
          <div class="imgs imgs-right">
            <div class="scroller scroller-right">
              <img src="@/assets/img/img-lodging7.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging8.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging9.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging10.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging11.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging12.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging7.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging8.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging9.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging10.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging11.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging12.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging7.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging8.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging9.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging10.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging11.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
              <img src="@/assets/img/img-lodging12.webp" alt="Chalet Luxueux" loading="lazy" width="326" height="219" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Arguments -->
    <section class="arguments-list site-max-width">
      <article class="argument">
        <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg" data-inview="fadeInUp" data-delay="100">
          <path
            d="M27.1634 4.16161L27.1633 4.16164L27.1652 4.16283L49.1863 18.1458C49.3442 18.3063 49.4844 18.5294 49.4844 18.7375V47.8882C49.4844 48.7544 48.7753 49.4635 47.9091 49.4635H36.5169C35.6507 49.4635 34.9416 48.7544 34.9416 47.8882V38.7576C34.9416 36.1894 32.8026 34.0505 30.2344 34.0505H23.2818C20.7148 34.0505 18.5737 36.1886 18.6584 38.7593V47.9719C18.6584 48.8381 17.9493 49.5472 17.0831 49.5472H5.69091C4.82471 49.5472 4.11558 48.8381 4.11558 47.9719V18.7375C4.11558 18.5096 4.19006 18.2951 4.40514 18.1512C4.40546 18.151 4.40579 18.1508 4.40612 18.1506L26.5184 4.16292L26.5278 4.15698L26.5356 4.14912C26.5958 4.089 26.693 4.05276 26.8105 4.05276C26.927 4.05276 27.0542 4.08886 27.1634 4.16161ZM36.6007 52.6791H47.9091C50.5622 52.6791 52.7 50.539 52.7 47.8044V18.6537C52.7 17.3662 52.0987 16.1597 50.9776 15.4695C50.9774 15.4694 50.9772 15.4693 50.9771 15.4692L28.8645 1.4813C28.8644 1.48124 28.8643 1.48117 28.8641 1.4811C27.6586 0.7063 26.0252 0.7063 24.8196 1.4811C24.8195 1.48117 24.8194 1.48124 24.8193 1.4813L2.70668 15.4692C2.70651 15.4693 2.70633 15.4694 2.70616 15.4695C1.58891 16.1573 0.9 17.3616 0.9 18.6537V47.8882C0.9 50.4564 3.03893 52.5953 5.60714 52.5953H17.0831C19.6513 52.5953 21.7903 50.4564 21.7903 47.8882V38.7576C21.7903 37.8914 22.4994 37.1823 23.3656 37.1823H30.3182C31.1844 37.1823 31.8935 37.8914 31.8935 38.7576V47.9719C31.8935 50.5401 34.0324 52.6791 36.6007 52.6791Z"
            fill="#2C444D"
            stroke="#2C444D"
            stroke-width="0.2"
          />
        </svg>
        <h2 class="small-title" data-inview="fadeInUp" data-delay="300">{{ $t('heb_argument_title_1') }}</h2>
        <p class="regular-text" data-inview="fadeInUp" data-delay="400">
          {{ $t('heb_argument_desc_1') }}
        </p>
      </article>
      <article class="argument">
        <svg width="70" height="51" viewBox="0 0 70 51" fill="none" xmlns="http://www.w3.org/2000/svg" data-inview="fadeInUp" data-delay="200">
          <path
            d="M68.8999 46.6472C67.8507 45.8735 66.9111 44.9604 66.1009 43.9378C64.1286 41.6501 61.8941 39.1001 57.4249 39.1001C52.9525 39.1001 50.7179 41.6501 48.7456 43.9378C46.9592 45.9865 45.5481 47.6001 42.6329 47.6001C39.7176 47.6001 38.2998 45.9865 36.5135 43.9378C34.5413 41.6501 32.3067 39.1001 27.8342 39.1001C23.3617 39.1001 21.1272 41.6501 19.1548 43.9378C17.3785 45.9865 15.9673 47.6001 13.0454 47.6001C10.1202 47.6001 8.71909 45.9865 6.93272 43.9378C5.71085 42.3308 4.16691 40.996 2.40383 40.0165C1.56711 39.638 0.577691 39.9932 0.17258 40.8167C-0.229181 41.6434 0.0928927 42.6395 0.906369 43.0711C2.25439 43.8614 3.42985 44.9139 4.36613 46.1623C6.34833 48.45 8.5829 51 13.0455 51C17.5081 51 19.7525 48.45 21.7249 46.1623C23.5079 44.1137 24.919 42.5 27.8342 42.5C30.7495 42.5 32.1606 44.1137 33.9469 46.1623C35.9192 48.45 38.1538 51 42.6331 51C47.1124 51 49.3401 48.45 51.3125 46.1623C53.0888 44.1137 54.5 42.5 57.4252 42.5C60.3504 42.5 61.7615 44.1137 63.5379 46.1623H63.5345C64.5406 47.4274 65.716 48.5463 67.0275 49.486C67.4061 49.745 67.8676 49.838 68.3158 49.7516C68.764 49.662 69.1558 49.3997 69.4082 49.0179C69.6572 48.636 69.7436 48.1712 69.6506 47.7263C69.5543 47.2814 69.2821 46.8929 68.9002 46.6472L68.8999 46.6472Z"
            fill="#2C444D"
          />
          <path
            d="M68.8999 33.8972C67.8507 33.1235 66.9111 32.2104 66.1009 31.1878C64.1286 28.9001 61.8941 26.3501 57.4249 26.3501C52.9525 26.3501 50.7179 28.9001 48.7456 31.1878C46.9592 33.2365 45.5481 34.8501 42.6262 34.8501C39.701 34.8501 38.2999 33.2365 36.5135 31.1878C34.5413 28.9001 32.3067 26.3501 27.8342 26.3501C23.3617 26.3501 21.1272 28.9001 19.1548 31.1878C17.3785 33.2365 15.9673 34.8501 13.0454 34.8501C10.1202 34.8501 8.71909 33.2365 6.93272 31.1878C5.71085 29.5808 4.16691 28.246 2.40383 27.2665C1.56711 26.888 0.577691 27.2432 0.17258 28.0667C-0.229181 28.8934 0.0928927 29.8895 0.906369 30.3211C2.25439 31.1114 3.42985 32.1639 4.36613 33.4123C6.34833 35.7 8.5829 38.25 13.0455 38.25C17.5081 38.25 19.7525 35.7 21.7249 33.4123C23.5079 31.3637 24.9124 29.75 27.8342 29.75C30.7594 29.75 32.1706 31.3637 33.9469 33.4123C35.9192 35.7 38.1538 38.25 42.6263 38.25C47.0988 38.25 49.3333 35.7 51.3057 33.4123C53.0887 31.3637 54.4998 29.75 57.425 29.75C60.3502 29.75 61.7614 31.3637 63.5377 33.4123H63.5344C64.5404 34.6774 65.7158 35.7963 67.0274 36.736C67.4059 36.995 67.8674 37.088 68.3156 37.0016C68.7639 36.912 69.1557 36.6497 69.4081 36.2679C69.6571 35.886 69.7434 35.4212 69.6504 34.9763C69.5541 34.5314 69.2819 34.1429 68.9 33.8972L68.8999 33.8972Z"
            fill="#2C444D"
          />
          <path
            d="M68.8999 20.2971C67.8507 19.5234 66.9111 18.6103 66.1009 17.5877C64.1286 15.3 61.8941 12.75 57.4249 12.75C52.9525 12.75 50.7179 15.3 48.7456 17.5877C46.9592 19.6364 45.5481 21.25 42.6329 21.25C39.7176 21.25 38.2998 19.6364 36.5135 17.5877C34.5413 15.3 32.3067 12.75 27.8342 12.75C23.3617 12.75 21.1272 15.3 19.1548 17.5877C17.3785 19.6364 15.9673 21.25 13.0454 21.25C10.1202 21.25 8.71909 19.6364 6.93272 17.5877C5.71085 15.9807 4.16691 14.6459 2.40383 13.6664C1.56711 13.2879 0.577691 13.6431 0.17258 14.4666C-0.229181 15.2934 0.0928927 16.2894 0.906369 16.721C2.25439 17.5113 3.42985 18.5638 4.36613 19.8122C6.34833 22.0999 8.5829 24.6499 13.0455 24.6499C17.5081 24.6499 19.7525 22.0999 21.7249 19.8122C23.5079 17.7636 24.919 16.1499 27.8342 16.1499C30.7495 16.1499 32.1606 17.7636 33.9469 19.8122C35.9192 22.0999 38.1538 24.6499 42.6331 24.6499C47.1124 24.6499 49.3401 22.0999 51.3125 19.8122C53.0888 17.7636 54.5 16.1499 57.4252 16.1499C60.3504 16.1499 61.7615 17.7636 63.5379 19.8122H63.5345C64.5406 21.0773 65.716 22.1962 67.0275 23.1359C67.4061 23.3949 67.8676 23.4879 68.3158 23.4015C68.764 23.3119 69.1558 23.0496 69.4082 22.6678C69.6572 22.2859 69.7436 21.8211 69.6506 21.3762C69.5543 20.9313 69.2821 20.5428 68.9002 20.2971L68.8999 20.2971Z"
            fill="#2C444D"
          />
          <path
            d="M68.8999 7.54708C67.8507 6.77344 66.9111 5.86033 66.1009 4.8377C64.1286 2.55 61.8941 0 57.4249 0C52.9525 0 50.7179 2.55 48.7456 4.8377C46.9592 6.88637 45.5481 8.50001 42.6262 8.50001C39.701 8.50001 38.2999 6.88637 36.5135 4.8377C34.5413 2.55 32.3067 0 27.8342 0C23.3617 0 21.1272 2.55 19.1548 4.8377C17.3785 6.88637 15.9673 8.50001 13.0454 8.50001C10.1202 8.50001 8.71909 6.88637 6.93272 4.8377C5.71085 3.23069 4.16691 1.89593 2.40383 0.916387C1.56711 0.537872 0.577691 0.893144 0.17258 1.71659C-0.229181 2.54335 0.0928927 3.53941 0.906369 3.97104C2.25439 4.76128 3.42985 5.81385 4.36613 7.06225C6.34833 9.34994 8.5829 11.8999 13.0455 11.8999C17.5081 11.8999 19.7525 9.34994 21.7249 7.06225C23.5079 5.01357 24.9124 3.39993 27.8342 3.39993C30.7594 3.39993 32.1706 5.01357 33.9469 7.06225C35.9192 9.34994 38.1538 11.8999 42.6263 11.8999C47.0988 11.8999 49.3333 9.34994 51.3057 7.06225C53.0887 5.01357 54.4998 3.39993 57.425 3.39993C60.3502 3.39993 61.7614 5.01357 63.5377 7.06225H63.5344C64.5404 8.3273 65.7158 9.44624 67.0274 10.3859C67.4059 10.6449 67.8674 10.7379 68.3156 10.6515C68.7639 10.5619 69.1557 10.2996 69.4081 9.91776C69.6571 9.53592 69.7434 9.07108 69.6504 8.62618C69.5541 8.18125 69.2819 7.79278 68.9 7.5471L68.8999 7.54708Z"
            fill="#2C444D"
          />
        </svg>
        <h2 class="small-title" data-inview="fadeInUp" data-delay="400">{{ $t('heb_argument_title_2') }}</h2>
        <p class="regular-text" data-inview="fadeInUp" data-delay="500">
          {{ $t('heb_argument_desc_2') }}
        </p>
      </article>
      <article class="argument">
        <svg width="62" height="54" viewBox="0 0 62 54" fill="none" xmlns="http://www.w3.org/2000/svg" data-inview="fadeInUp" data-delay="300">
          <path
            d="M23.925 17.6005C23.925 21.5005 27.125 24.7005 31.025 24.7005C34.925 24.7005 38.125 21.5005 38.125 17.6005C38.125 13.7005 34.925 10.5005 31.025 10.5005C27.125 10.6005 23.925 13.8005 23.925 17.6005ZM34.525 17.6005C34.525 19.6005 32.925 21.2005 30.925 21.2005C28.925 21.2005 27.325 19.6005 27.325 17.6005C27.325 15.6005 28.925 14.0005 30.925 14.0005C32.925 14.0005 34.525 15.7005 34.525 17.6005Z"
            fill="#2C444D"
          />
          <path
            d="M61.0251 36.2005L56.3251 31.5005C55.8251 31.0005 55.0251 30.8005 54.3251 31.1005L49.1251 33.4005C48.2251 33.8005 47.8251 34.8005 48.2251 35.7005C48.6251 36.6005 49.6251 37.0005 50.5251 36.6005L52.6251 35.7005C50.7251 40.6005 47.2251 44.6005 42.8251 47.2005V37.2005C42.8251 32.1005 39.1251 28.0005 34.4251 28.0005H26.8251C22.2251 28.0005 18.4251 32.1005 18.4251 37.2005V46.9005C13.9251 44.1005 10.4251 39.7005 8.72511 34.4005C8.42511 33.5005 7.42511 33.0005 6.52511 33.3005C5.62511 33.6005 5.12511 34.6005 5.42511 35.5005C9.02511 46.6005 19.3251 54.0005 30.9251 54.0005C42.3251 54.0005 52.3251 46.9005 56.2251 36.2005L58.5251 38.5005C58.8251 38.8005 59.3251 39.0005 59.7251 39.0005C60.1251 39.0005 60.6251 38.8005 60.9251 38.5005C61.7251 38.0005 61.7251 36.9005 61.0251 36.2005ZM22.0251 48.8005V37.3005C22.0251 34.2005 24.2251 31.6005 26.9251 31.6005H30.2251L28.5251 36.4005C28.3251 37.0005 28.3251 37.6005 28.5251 38.1005L29.9251 42.7005C30.2251 43.6005 31.4251 43.6005 31.7251 42.7005L33.1251 38.1005C33.3251 37.5005 33.3251 36.9005 33.1251 36.4005L31.4251 31.6005H34.7251C37.4251 31.6005 39.6251 34.1005 39.6251 37.3005V49.0005C36.9251 50.0005 34.0251 50.6005 31.0251 50.6005C27.8251 50.6005 24.8251 50.0005 22.0251 48.8005Z"
            fill="#2C444D"
          />
          <path
            d="M7.325 25.8004L12.925 22.8004C13.825 22.3004 14.125 21.3004 13.625 20.4004C13.125 19.6004 12.125 19.2004 11.225 19.7004L8.425 21.2004C11.125 11.0004 20.325 3.80039 31.025 3.80039C41.825 3.80039 51.125 11.1004 53.825 21.6004C54.025 22.5004 55.025 23.1004 55.925 22.9004C56.825 22.7004 57.425 21.7004 57.225 20.8004C54.225 8.80039 43.425 0.400391 31.025 0.400391C18.825 0.400391 8.225 8.60039 5.025 20.4004L3.025 18.4004C2.325 17.7004 1.225 17.7004 0.525 18.4004C-0.175 19.1004 -0.175 20.2004 0.525 20.9004L5.225 25.6004C5.525 25.9004 6.025 26.1004 6.425 26.1004C6.825 26.0004 7.025 25.9004 7.325 25.8004Z"
            fill="#2C444D"
          />
        </svg>
        <h2 class="small-title" data-inview="fadeInUp" data-delay="600">{{ $t('heb_argument_title_3') }}</h2>
        <p class="regular-text" data-inview="fadeInUp" data-delay="700">
          {{ $t('heb_argument_desc_3') }}
        </p>
      </article>
    </section>

    <section class="scroll_logo">
      <div class="logo_wrap" data-inview="fadeInUp" data-delay="300">
        <div class="line line1">
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-holiday-inn-tremblant.webp" alt="Holiday Inn Express & Suites Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-fairmont-tremblant.webp" alt="Fairmont Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-homewood-suites-tremblant.webp" alt="Homewoods Suites Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-westin-tremblant-2.webp" alt="Westin Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-residence-inn.webp" alt="Redisence Inn Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-rendez-vous-tremblant.webp" alt="Rendez-vous Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-le-sommet-des-neiges-tremblant.webp" alt="Le sommets des neiges Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-le-johannsen-tremblant.webp" alt="Johennsen Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-la-tour-des-voyageurs-tremblant.webp" alt="La tour des voyageurs Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-tremblant-a-louer.webp" alt="Tremblant à louer Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-le-deslauriers-tremblant.webp" alt="Le Deslauriers Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-elysium-tremblant.webp" alt="Elysium Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-ermitage-du-lac-tremblant.webp" alt="Ermitage du lac Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-lodge-tremblant.webp" alt="Le Lodge de la montagne Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-bondurant-tremblant.webp" alt="Le Bondurant Tremblant Logo" /></div>
          <!-- <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-sunstar-tremblant.webp" alt="Sunstar Tremblant Logo" /></div> -->
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-tremblant-hilton-grand-vacations-club.webp" alt="Sunstar Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-holiday-inn-tremblant.webp" alt="Holiday Inn Express & Suites Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-fairmont-tremblant.webp" alt="Fairmont Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-homewood-suites-tremblant.webp" alt="Homewoods Suites Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-westin-tremblant-2.webp" alt="Westin Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-residence-inn.webp" alt="Redisence Inn Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-rendez-vous-tremblant.webp" alt="Rendez-vous Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-le-sommet-des-neiges-tremblant.webp" alt="Le sommets des neiges Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-le-johannsen-tremblant.webp" alt="Johennsen Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-la-tour-des-voyageurs-tremblant.webp" alt="La tour des voyageurs Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-tremblant-a-louer.webp" alt="Tremblant à louer Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-le-deslauriers-tremblant.webp" alt="Le Deslauriers Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-elysium-tremblant.webp" alt="Elysium Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-ermitage-du-lac-tremblant.webp" alt="Ermitage du lac Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-lodge-tremblant.webp" alt="Le Lodge de la montagne Tremblant Logo" /></div>
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-bondurant-tremblant.webp" alt="Le Bondurant Tremblant Logo" /></div>
          <!-- <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-sunstar-tremblant.webp" alt="Sunstar Tremblant Logo" /></div> -->
          <div class="logo"><img loading="lazy" height="120" src="@/assets/img/logo-tremblant-hilton-grand-vacations-club.webp" alt="Sunstar Tremblant Logo" /></div>
        </div>
      </div>
    </section>

    <!-- Slider -->
    <section class="simple-slider" id="espaces-rencontres">
      <div class="slider-intro">
        <h2 class="big-title" data-inview="fadeInUp" data-delay="100">{{ $t('espace_title') }}</h2>
        <p class="medium-text" data-inview="fadeInUp" data-delay="300">
          {{ $t('espace_desc') }}
        </p>
        <div data-inview="fadeInUp" data-delay="400"><router-link :to="{ name: $route.meta.lang === 'fr' ? 'soumission' : 'soumission-en' }" class="btn cta" title="Demandez une soumission" aria-label="Lien vers la page du formulaire de soumission">{{ $t('cta') }}</router-link></div>
      </div>
      <div class="slider-caroucel-wrap">
        <div class="slider-carousel simple1">
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/espace-rencontre-casino.webp" width="816" height="531" alt="Espaces de rencontre Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/espace-rencontre-1.webp" width="816" height="531" alt="Espaces de rencontre Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/espace-rencontre-2.webp" width="816" height="531" alt="Espaces de rencontre Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/espace-rencontre-3.webp" width="816" height="531" alt="Espaces de rencontre Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/espace-rencontre-4-new.webp" width="816" height="531" alt="Espaces de rencontre Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/espace-rencontre-5.webp" width="816" height="531" alt="Espaces de rencontre Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/espace-rencontre-6.webp" width="816" height="531" alt="Espaces de rencontre Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
        </div>
        <div class="opacity-left"></div>
        <div class="opacity-right"></div>
      </div>
      
    </section>

    <!-- Arguments -->
    <section class="arguments-list site-max-width">
      <article class="argument">
        <SvgConference data-inview="fadeInUp" data-delay="100" />
        <h2 class="small-title" data-inview="fadeInUp" data-delay="300">{{ $t('espace_argument_title_1') }}</h2>
        <p class="regular-text" data-inview="fadeInUp" data-delay="500">{{ $t('espace_argument_desc_2') }}</p>
      </article>
      <article class="argument">
        <svg width="62" height="46" viewBox="0 0 62 46" fill="none" xmlns="http://www.w3.org/2000/svg" data-inview="fadeInUp" data-delay="200">
          <path
            d="M31.0004 0C19.43 0 8.81481 3.84149 0.534974 10.0405C-0.0542863 10.4843 -0.178181 11.4182 0.270634 12.0008C0.719448 12.5834 1.66395 12.7059 2.25318 12.2621C10.0565 6.41958 20.0634 2.78753 31 2.78753C41.9366 2.78753 51.9435 6.41937 59.7468 12.2621C60.3361 12.7059 61.2806 12.5834 61.7294 12.0008C62.1782 11.4181 62.0543 10.4843 61.465 10.0405C53.1852 3.84135 42.57 0 30.9996 0H31.0004ZM31.0004 13.2424C22.7092 13.2424 14.9799 15.7227 8.97201 20.5174C8.3084 20.9394 8.14318 21.9576 8.63883 22.5647C9.13447 23.1719 10.1753 23.2263 10.7343 22.6736C16.1946 18.3176 23.2795 16.0307 31.0004 16.0307C38.7212 16.0307 45.8062 18.3176 51.2665 22.6736C51.8227 23.2263 52.8662 23.1719 53.3619 22.5647C53.8576 21.9576 53.6924 20.9394 53.0288 20.5174C47.0205 15.723 39.2915 13.2424 31.0004 13.2424H31.0004ZM31.0004 25.7879C25.9393 25.7879 21.1288 27.3561 17.4309 30.2528C16.8471 30.7102 16.737 31.6467 17.1996 32.2239C17.6622 32.8011 18.6122 32.91 19.1931 32.4526C22.3515 29.9778 26.534 28.5757 31.0003 28.5757C35.4666 28.5757 39.6495 29.9778 42.8075 32.4526C43.3913 32.91 44.3413 32.8011 44.8011 32.2239C45.2637 31.6467 45.1535 30.7074 44.5698 30.2528C40.8718 27.3561 36.0616 25.7879 31.0003 25.7879H31.0004ZM31.0004 34.8485C27.8861 34.8485 25.3611 37.345 25.3611 40.4242C25.3611 43.5035 27.8861 46 31.0004 46C34.1146 46 36.6396 43.5035 36.6396 40.4242C36.6396 37.345 34.1146 34.8485 31.0004 34.8485Z"
            fill="#2C444D"
          />
        </svg>
        <h2 class="small-title" data-inview="fadeInUp" data-delay="400">{{ $t('espace_argument_title_2') }}</h2>
        <p class="regular-text" data-inview="fadeInUp" data-delay="600">{{ $t('espace_argument_desc_2') }}</p>
      </article>
      <article class="argument">
        <svg width="62" height="46" viewBox="0 0 62 46" fill="none" xmlns="http://www.w3.org/2000/svg" data-inview="fadeInUp" data-delay="300">
          <path
            d="M58.3 31.5H56.3C54.9 18.4 44.9 8.2 32.5 7.3V3.6H34.2C35.2 3.6 36 2.8 36 1.8C36 0.8 35.2 0 34.2 0H27.3C26.3 0 25.5 0.8 25.5 1.8C25.5 2.8 26.3 3.6 27.3 3.6H29V7.3C16.6 8.2 6.5 18.4 5.1 31.5H3.1C1.4 31.5 0 32.9 0 34.6V39.8C0 43.1 2.7 45.7 5.9 45.7H55.5C58.8 45.7 61.4 43 61.4 39.8V34.6C61.5 33 60 31.5 58.3 31.5ZM30.7 10.8C42 10.8 51.3 19.8 52.8 31.6H8.6C10.1 19.7 19.4 10.8 30.7 10.8ZM58 39.9C58 41.2 56.9 42.3 55.6 42.3H5.9C4.6 42.3 3.5 41.2 3.5 39.9V35H58V39.9Z"
            fill="#2C444D"
          />
        </svg>
        <h2 class="small-title" data-inview="fadeInUp" data-delay="500">{{ $t('espace_argument_title_3') }}</h2>
        <p class="regular-text" data-inview="fadeInUp" data-delay="700">
          {{ $t('espace_argument_desc_3') }}
        </p>
      </article>
    </section>

    <!-- Split -->
    <section class="split site-max-width">
      <div class="left" data-inview="fadeInUp" data-delay="100">
        <img src="@/assets/img/camp-de-base2.webp" width="648" height="453" loading="lazy" alt="Sommet Tremblant avec logos ecoresponsables" />
      </div>
      <div class="right">
        <h3 class="regular-title" data-inview="fadeInUp" data-delay="200">
          {{ $t('cdb_title') }}
        </h3>
        <p class="regular-text" data-inview="fadeInUp" data-delay="400">
          {{ $t('cdb_sub_title') }}
        </p>
        <p class="regular-text" data-inview="fadeInUp" data-delay="400" v-html="$t('cdb_desc')">
        </p>
        <div data-inview="fadeInUp" data-delay="500"><router-link :to="{ name: $route.meta.lang === 'fr' ? 'soumission' : 'soumission-en' }" class="btn cta" title="Demandez une soumission" aria-label="Lien vers la page du formulaire de soumission">{{ $t('cta') }}</router-link></div>
      </div>
      <!-- <div class="right">
        <h3 class="regular-title" data-inview="fadeInUp" data-delay="200">
          Camp de Base Tremblant, c'est un espace où les rêves prennent vie et où les ambitions s'élèvent. 
        </h3>
        <p class="regular-text" data-inview="fadeInUp" data-delay="300">Camp de Base Tremblant s’est refait une beauté. Pour des rencontres plus grandes que nature, rendez-vous à Tremblant dès maintenant.</p>
        <p class="regular-text" data-inview="fadeInUp" data-delay="400">
          <span>PHASE 1:</span> Rénovations du 1er étage<br>
          <span>PHASE 2:</span> À venir, rénovations du 2e étage<br>
        </p>
      </div> -->
    </section>

    <!-- Informations -->
    <section class="informations-grid site-max-width">
      <div class="infos-intro">
        <h2 class="big-title" data-inview="fadeInUp" data-delay="100" v-html="$t('groups_title')"></h2>
        <p class="medium-text" data-inview="fadeInUp" data-delay="300">
          {{ $t('groups_desc') }}
        </p>
      </div>
      <div class="infos-grid">
        <article class="item" data-inview="fadeInUp" data-delay="400">
          <img loading="lazy" src="@/assets/img/affaires.webp" width="430" height="511" alt="Rencontre d'affaires à l'extérieur" />
          <h3 class="regular-title">{{ $t('groups_1') }}</h3>
        </article>
        <article class="item" data-inview="fadeInUp" data-delay="500">
          <img loading="lazy" src="@/assets/img/loisirs.webp" width="430" height="511" alt="4 Personnes allant faire de la planche à neige" />
          <h3 class="regular-title">{{ $t('groups_2') }}</h3>
        </article>
        <article class="item" data-inview="fadeInUp" data-delay="600">
          <img loading="lazy" src="@/assets/img/team.webp" width="430" height="511" alt="Activité de Team Building dans les montagnes" />
          <h3 class="regular-title" v-html="$t('groups_3')">
          </h3>
        </article>
      </div>
    </section>

    <!-- Slider -->
    <section class="simple-slider" id="activite-equipe">
      <div class="slider-intro">
        <h2 class="big-title" data-inview="fadeInUp" data-delay="100" v-html="$t('activite_title')"></h2>
        <p class="medium-text larger" data-inview="fadeInUp" data-delay="300">
          {{ $t('activite_desc') }}
        </p>
        <div data-inview="fadeInUp" data-delay="400"><router-link :to="{ name: $route.meta.lang === 'fr' ? 'soumission' : 'soumission-en' }" class="btn cta" title="Demandez une soumission" aria-label="Lien vers la page du formulaire de soumission">{{ $t('cta') }}</router-link></div>
      </div>

      <div class="slider-caroucel-wrap">
        <div class="slider-carousel simple2">
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/activites-1-new.webp" width="816" height="541" alt="Luge Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/activites-2.webp" width="816" height="541" alt="Raquette Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/activites-3.webp" width="816" height="541" alt="Golf Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/activites-4.webp" width="816" height="541" alt="Restaurant Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/activites-5.webp" width="816" height="541" alt="Team building Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/activites-6.webp" width="816" height="541" alt="Vélo montagne Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/activites-7.webp" width="816" height="541" alt="Souper refuge Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
          <div class="carousel-cell">
            <img data-flickity-lazyload="/img/activites-8.webp" width="816" height="541" alt="Traiteur Tremblant" />
            <!-- <span class="label">Rénos en cours, ouverture mai 2022</span> -->
          </div>
        </div>
        <div class="opacity-left"></div>
        <div class="opacity-right"></div>
      </div>
      
      
    </section>

    <!-- Arguments -->
    <section class="arguments-list site-max-width">
      <article class="argument">
        <SvgActivity data-inview="fadeInUp" data-delay="100" />
        <h2 class="small-title" data-inview="fadeInUp" data-delay="300">{{ $t('activite_argument_title_1') }}</h2>
        <p class="regular-text" data-inview="fadeInUp" data-delay="600" v-html="$t('activite_argument_desc_1')">
        </p>
      </article>
      <article class="argument">
        <svg width="43" height="53" viewBox="0 0 43 53" fill="none" xmlns="http://www.w3.org/2000/svg" data-inview="fadeInUp" data-delay="200">
          <path
            d="M33.747 0.799805H30.9836C29.4763 0.799805 28.304 1.97216 28.304 3.47948V48.4478C28.304 50.5413 30.0625 52.2998 32.156 52.2998H34.5844C36.6779 52.2998 38.4365 50.5413 38.4365 48.4478V30.6112H40.195C41.7023 30.6112 42.8747 29.4388 42.8747 27.9315V9.92745C42.7909 4.81932 38.6877 0.799805 33.747 0.799805ZM34.5844 49.3689H32.156C31.6535 49.3689 31.2348 48.9502 31.2348 48.4478V30.6112H35.5056V48.364C35.5056 48.8665 35.0869 49.3689 34.5844 49.3689ZM39.9438 27.6803H36.9292H35.4218H31.1511V3.7307H33.6633C37.0966 3.7307 39.8601 6.49411 39.8601 9.92745V27.6803H39.9438Z"
            fill="#2C444D"
          />
          <path
            d="M16.748 0.967529C15.9106 0.967529 15.2406 1.63745 15.2406 2.47485V15.2033H10.5512V2.39111C10.5512 1.55371 9.8813 0.883789 9.0439 0.883789C8.2065 0.883789 7.53658 1.55371 7.53658 2.39111V15.1196H2.93089V2.39111C2.93089 1.55371 2.26098 0.883789 1.42358 0.883789C0.586179 0.883789 0 1.63745 0 2.39111V23.7448C0 25.0009 0.921138 25.922 2.17724 25.922H4.10325V48.3643C4.10325 50.4578 5.86179 52.2163 7.95528 52.2163H10.3C12.3935 52.2163 14.152 50.4578 14.152 48.3643V26.0057H16.078C17.3341 26.0057 18.2553 25.0846 18.2553 23.8285V2.39111C18.1715 1.63745 17.5854 0.967529 16.748 0.967529ZM11.2211 48.448C11.2211 48.9505 10.8024 49.3692 10.3 49.3692H7.95528C7.45284 49.3692 7.03415 48.9505 7.03415 48.448V26.0057H11.2211V48.448ZM2.93089 23.0748V18.0505H15.3244V23.0748H2.93089Z"
            fill="#2C444D"
          />
        </svg>
        <h2 class="small-title" data-inview="fadeInUp" data-delay="400">{{ $t('activite_argument_title_2') }}</h2>
        <p class="regular-text" data-inview="fadeInUp" data-delay="500" v-html="$t('activite_argument_desc_2')">
        </p>
      </article>
      <article class="argument">
        <SvgTeam data-inview="fadeInUp" data-delay="300" />
        <h2 class="small-title" data-inview="fadeInUp" data-delay="500">{{ $t('activite_argument_title_3') }}</h2>
        <p class="regular-text" data-inview="fadeInUp" data-delay="600" v-html="$t('activite_argument_desc_3')">
        </p>
      </article>
    </section>

    <!-- Split -->
    <section class="split reverse site-max-width">
      <div class="left" data-inview="fadeInUp" data-delay="100">
        <img loading="lazy" src="@/assets/img/transport-terrestre-2.webp" width="648" height="453" alt="Transport terrestre bus" />
      </div>
      <div class="right">
        <h3 class="regular-title" data-inview="fadeInUp" data-delay="200">
          {{ $t('bus_title') }}
        </h3>
        <p class="regular-text" data-inview="fadeInUp" data-delay="300" v-html="$t('bus_desc')">
        </p>
        <div>
          <a
            :href="$t('bus_cta_link')"
            target="_blank"
            class="btn is-alternate"
            data-inview="fadeInUp"
            data-delay="400"
            >{{ $t('bus_cta') }}</a
          >
        </div>
      </div>
    </section>

    <!-- Testimonials -->
    <section class="testimonials site-max-width" data-inview="fadeInUp" data-delay="100">
      <div class="testimonials-carousel">

        <div class="carousel-cell">
          <div class="left">
            <img data-flickity-lazyload="/img/testimonial8.webp" width="300" height="305" alt="Joeurs de Golf" />
          </div>
          <div class="right">
            <svg height="12" viewBox="0 0 118 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.98156 0.000317737C8.81058 0.00744045 8.66197 0.119031 8.60924 0.280477L6.68288 6.19547L0.410036 6.18281C0.23266 6.18281 0.0744666 6.29598 0.0201407 6.46297C-0.034989 6.63075 0.0257337 6.81357 0.169551 6.91724L5.25279 10.56L3.30086 16.4688C3.24573 16.6357 3.30565 16.8194 3.44947 16.9222C3.59249 17.0259 3.78743 17.0259 3.93047 16.9222L8.99918 13.2588L14.0695 16.9222C14.2126 17.0259 14.4075 17.0259 14.5505 16.9222C14.6943 16.8194 14.7543 16.6357 14.6991 16.4688L12.7472 10.56L17.8304 6.91724C17.9743 6.81357 18.035 6.63074 17.9799 6.46297C17.9247 6.29598 17.7673 6.18281 17.59 6.1836L11.3171 6.19626L9.38837 0.281267V0.280476C9.33164 0.107155 9.16546 -0.00681505 8.98168 0.000316578L8.98156 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.9816 0.000317737C33.8106 0.00744045 33.662 0.119031 33.6092 0.280477L31.6829 6.19547L25.41 6.18281C25.2327 6.18281 25.0745 6.29598 25.0201 6.46297C24.965 6.63075 25.0257 6.81357 25.1696 6.91724L30.2528 10.56L28.3009 16.4688C28.2457 16.6357 28.3057 16.8194 28.4495 16.9222C28.5925 17.0259 28.7874 17.0259 28.9305 16.9222L33.9992 13.2588L39.0695 16.9222C39.2126 17.0259 39.4075 17.0259 39.5505 16.9222C39.6943 16.8194 39.7543 16.6357 39.6991 16.4688L37.7472 10.56L42.8304 6.91724C42.9743 6.81357 43.035 6.63074 42.9799 6.46297C42.9247 6.29598 42.7673 6.18281 42.59 6.1836L36.3171 6.19626L34.3884 0.281267V0.280476C34.3316 0.107155 34.1655 -0.00681505 33.9817 0.000316578L33.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M58.9816 0.000317737C58.8106 0.00744045 58.662 0.119031 58.6092 0.280477L56.6829 6.19547L50.41 6.18281C50.2327 6.18281 50.0745 6.29598 50.0201 6.46297C49.965 6.63075 50.0257 6.81357 50.1696 6.91724L55.2528 10.56L53.3009 16.4688C53.2457 16.6357 53.3057 16.8194 53.4495 16.9222C53.5925 17.0259 53.7874 17.0259 53.9305 16.9222L58.9992 13.2588L64.0695 16.9222C64.2126 17.0259 64.4075 17.0259 64.5505 16.9222C64.6943 16.8194 64.7543 16.6357 64.6991 16.4688L62.7472 10.56L67.8304 6.91724C67.9743 6.81357 68.035 6.63074 67.9799 6.46297C67.9247 6.29598 67.7673 6.18281 67.59 6.1836L61.3171 6.19626L59.3884 0.281267V0.280476C59.3316 0.107155 59.1655 -0.00681505 58.9817 0.000316578L58.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M83.9816 0.000317737C83.8106 0.00744045 83.662 0.119031 83.6092 0.280477L81.6829 6.19547L75.41 6.18281C75.2327 6.18281 75.0745 6.29598 75.0201 6.46297C74.965 6.63075 75.0257 6.81357 75.1696 6.91724L80.2528 10.56L78.3009 16.4688C78.2457 16.6357 78.3057 16.8194 78.4495 16.9222C78.5925 17.0259 78.7874 17.0259 78.9305 16.9222L83.9992 13.2588L89.0695 16.9222C89.2126 17.0259 89.4075 17.0259 89.5505 16.9222C89.6943 16.8194 89.7543 16.6357 89.6991 16.4688L87.7472 10.56L92.8304 6.91724C92.9743 6.81357 93.035 6.63074 92.9799 6.46297C92.9247 6.29598 92.7673 6.18281 92.59 6.1836L86.3171 6.19626L84.3884 0.281267V0.280476C84.3316 0.107155 84.1655 -0.00681505 83.9817 0.000316578L83.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.982 0.000317737C108.811 0.00744045 108.662 0.119031 108.609 0.280477L106.683 6.19547L100.41 6.18281C100.233 6.18281 100.074 6.29598 100.02 6.46297C99.965 6.63075 100.026 6.81357 100.17 6.91724L105.253 10.56L103.301 16.4688C103.246 16.6357 103.306 16.8194 103.449 16.9222C103.592 17.0259 103.787 17.0259 103.93 16.9222L108.999 13.2588L114.07 16.9222C114.213 17.0259 114.407 17.0259 114.551 16.9222C114.694 16.8194 114.754 16.6357 114.699 16.4688L112.747 10.56L117.83 6.91724C117.974 6.81357 118.035 6.63074 117.98 6.46297C117.925 6.29598 117.767 6.18281 117.59 6.1836L111.317 6.19626L109.388 0.281267V0.280476C109.332 0.107155 109.165 -0.00681505 108.982 0.000316578L108.982 0.000317737Z"
                fill="#AB192D"
              />
            </svg>
            <p class="small-text" v-html="$t('testimonial_8')"></p>
          </div>
        </div>
        
        <div class="carousel-cell">
          <div class="left">
            <img data-flickity-lazyload="/img/testimonial-ferrari.webp" width="300" height="305" alt="Joeurs de Golf" />
          </div>
          <div class="right">
            <svg height="12" viewBox="0 0 118 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.98156 0.000317737C8.81058 0.00744045 8.66197 0.119031 8.60924 0.280477L6.68288 6.19547L0.410036 6.18281C0.23266 6.18281 0.0744666 6.29598 0.0201407 6.46297C-0.034989 6.63075 0.0257337 6.81357 0.169551 6.91724L5.25279 10.56L3.30086 16.4688C3.24573 16.6357 3.30565 16.8194 3.44947 16.9222C3.59249 17.0259 3.78743 17.0259 3.93047 16.9222L8.99918 13.2588L14.0695 16.9222C14.2126 17.0259 14.4075 17.0259 14.5505 16.9222C14.6943 16.8194 14.7543 16.6357 14.6991 16.4688L12.7472 10.56L17.8304 6.91724C17.9743 6.81357 18.035 6.63074 17.9799 6.46297C17.9247 6.29598 17.7673 6.18281 17.59 6.1836L11.3171 6.19626L9.38837 0.281267V0.280476C9.33164 0.107155 9.16546 -0.00681505 8.98168 0.000316578L8.98156 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.9816 0.000317737C33.8106 0.00744045 33.662 0.119031 33.6092 0.280477L31.6829 6.19547L25.41 6.18281C25.2327 6.18281 25.0745 6.29598 25.0201 6.46297C24.965 6.63075 25.0257 6.81357 25.1696 6.91724L30.2528 10.56L28.3009 16.4688C28.2457 16.6357 28.3057 16.8194 28.4495 16.9222C28.5925 17.0259 28.7874 17.0259 28.9305 16.9222L33.9992 13.2588L39.0695 16.9222C39.2126 17.0259 39.4075 17.0259 39.5505 16.9222C39.6943 16.8194 39.7543 16.6357 39.6991 16.4688L37.7472 10.56L42.8304 6.91724C42.9743 6.81357 43.035 6.63074 42.9799 6.46297C42.9247 6.29598 42.7673 6.18281 42.59 6.1836L36.3171 6.19626L34.3884 0.281267V0.280476C34.3316 0.107155 34.1655 -0.00681505 33.9817 0.000316578L33.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M58.9816 0.000317737C58.8106 0.00744045 58.662 0.119031 58.6092 0.280477L56.6829 6.19547L50.41 6.18281C50.2327 6.18281 50.0745 6.29598 50.0201 6.46297C49.965 6.63075 50.0257 6.81357 50.1696 6.91724L55.2528 10.56L53.3009 16.4688C53.2457 16.6357 53.3057 16.8194 53.4495 16.9222C53.5925 17.0259 53.7874 17.0259 53.9305 16.9222L58.9992 13.2588L64.0695 16.9222C64.2126 17.0259 64.4075 17.0259 64.5505 16.9222C64.6943 16.8194 64.7543 16.6357 64.6991 16.4688L62.7472 10.56L67.8304 6.91724C67.9743 6.81357 68.035 6.63074 67.9799 6.46297C67.9247 6.29598 67.7673 6.18281 67.59 6.1836L61.3171 6.19626L59.3884 0.281267V0.280476C59.3316 0.107155 59.1655 -0.00681505 58.9817 0.000316578L58.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M83.9816 0.000317737C83.8106 0.00744045 83.662 0.119031 83.6092 0.280477L81.6829 6.19547L75.41 6.18281C75.2327 6.18281 75.0745 6.29598 75.0201 6.46297C74.965 6.63075 75.0257 6.81357 75.1696 6.91724L80.2528 10.56L78.3009 16.4688C78.2457 16.6357 78.3057 16.8194 78.4495 16.9222C78.5925 17.0259 78.7874 17.0259 78.9305 16.9222L83.9992 13.2588L89.0695 16.9222C89.2126 17.0259 89.4075 17.0259 89.5505 16.9222C89.6943 16.8194 89.7543 16.6357 89.6991 16.4688L87.7472 10.56L92.8304 6.91724C92.9743 6.81357 93.035 6.63074 92.9799 6.46297C92.9247 6.29598 92.7673 6.18281 92.59 6.1836L86.3171 6.19626L84.3884 0.281267V0.280476C84.3316 0.107155 84.1655 -0.00681505 83.9817 0.000316578L83.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.982 0.000317737C108.811 0.00744045 108.662 0.119031 108.609 0.280477L106.683 6.19547L100.41 6.18281C100.233 6.18281 100.074 6.29598 100.02 6.46297C99.965 6.63075 100.026 6.81357 100.17 6.91724L105.253 10.56L103.301 16.4688C103.246 16.6357 103.306 16.8194 103.449 16.9222C103.592 17.0259 103.787 17.0259 103.93 16.9222L108.999 13.2588L114.07 16.9222C114.213 17.0259 114.407 17.0259 114.551 16.9222C114.694 16.8194 114.754 16.6357 114.699 16.4688L112.747 10.56L117.83 6.91724C117.974 6.81357 118.035 6.63074 117.98 6.46297C117.925 6.29598 117.767 6.18281 117.59 6.1836L111.317 6.19626L109.388 0.281267V0.280476C109.332 0.107155 109.165 -0.00681505 108.982 0.000316578L108.982 0.000317737Z"
                fill="#AB192D"
              />
            </svg>
            <p class="small-text" v-html="$t('testimonial_1')"></p>
          </div>
        </div>

        <div class="carousel-cell">
          <div class="left">
            <img data-flickity-lazyload="/img/testimonial1.webp" width="300" height="305" alt="Joeurs de Golf" />
          </div>
          <div class="right">
            <svg height="12" viewBox="0 0 118 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.98156 0.000317737C8.81058 0.00744045 8.66197 0.119031 8.60924 0.280477L6.68288 6.19547L0.410036 6.18281C0.23266 6.18281 0.0744666 6.29598 0.0201407 6.46297C-0.034989 6.63075 0.0257337 6.81357 0.169551 6.91724L5.25279 10.56L3.30086 16.4688C3.24573 16.6357 3.30565 16.8194 3.44947 16.9222C3.59249 17.0259 3.78743 17.0259 3.93047 16.9222L8.99918 13.2588L14.0695 16.9222C14.2126 17.0259 14.4075 17.0259 14.5505 16.9222C14.6943 16.8194 14.7543 16.6357 14.6991 16.4688L12.7472 10.56L17.8304 6.91724C17.9743 6.81357 18.035 6.63074 17.9799 6.46297C17.9247 6.29598 17.7673 6.18281 17.59 6.1836L11.3171 6.19626L9.38837 0.281267V0.280476C9.33164 0.107155 9.16546 -0.00681505 8.98168 0.000316578L8.98156 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.9816 0.000317737C33.8106 0.00744045 33.662 0.119031 33.6092 0.280477L31.6829 6.19547L25.41 6.18281C25.2327 6.18281 25.0745 6.29598 25.0201 6.46297C24.965 6.63075 25.0257 6.81357 25.1696 6.91724L30.2528 10.56L28.3009 16.4688C28.2457 16.6357 28.3057 16.8194 28.4495 16.9222C28.5925 17.0259 28.7874 17.0259 28.9305 16.9222L33.9992 13.2588L39.0695 16.9222C39.2126 17.0259 39.4075 17.0259 39.5505 16.9222C39.6943 16.8194 39.7543 16.6357 39.6991 16.4688L37.7472 10.56L42.8304 6.91724C42.9743 6.81357 43.035 6.63074 42.9799 6.46297C42.9247 6.29598 42.7673 6.18281 42.59 6.1836L36.3171 6.19626L34.3884 0.281267V0.280476C34.3316 0.107155 34.1655 -0.00681505 33.9817 0.000316578L33.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M58.9816 0.000317737C58.8106 0.00744045 58.662 0.119031 58.6092 0.280477L56.6829 6.19547L50.41 6.18281C50.2327 6.18281 50.0745 6.29598 50.0201 6.46297C49.965 6.63075 50.0257 6.81357 50.1696 6.91724L55.2528 10.56L53.3009 16.4688C53.2457 16.6357 53.3057 16.8194 53.4495 16.9222C53.5925 17.0259 53.7874 17.0259 53.9305 16.9222L58.9992 13.2588L64.0695 16.9222C64.2126 17.0259 64.4075 17.0259 64.5505 16.9222C64.6943 16.8194 64.7543 16.6357 64.6991 16.4688L62.7472 10.56L67.8304 6.91724C67.9743 6.81357 68.035 6.63074 67.9799 6.46297C67.9247 6.29598 67.7673 6.18281 67.59 6.1836L61.3171 6.19626L59.3884 0.281267V0.280476C59.3316 0.107155 59.1655 -0.00681505 58.9817 0.000316578L58.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M83.9816 0.000317737C83.8106 0.00744045 83.662 0.119031 83.6092 0.280477L81.6829 6.19547L75.41 6.18281C75.2327 6.18281 75.0745 6.29598 75.0201 6.46297C74.965 6.63075 75.0257 6.81357 75.1696 6.91724L80.2528 10.56L78.3009 16.4688C78.2457 16.6357 78.3057 16.8194 78.4495 16.9222C78.5925 17.0259 78.7874 17.0259 78.9305 16.9222L83.9992 13.2588L89.0695 16.9222C89.2126 17.0259 89.4075 17.0259 89.5505 16.9222C89.6943 16.8194 89.7543 16.6357 89.6991 16.4688L87.7472 10.56L92.8304 6.91724C92.9743 6.81357 93.035 6.63074 92.9799 6.46297C92.9247 6.29598 92.7673 6.18281 92.59 6.1836L86.3171 6.19626L84.3884 0.281267V0.280476C84.3316 0.107155 84.1655 -0.00681505 83.9817 0.000316578L83.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.982 0.000317737C108.811 0.00744045 108.662 0.119031 108.609 0.280477L106.683 6.19547L100.41 6.18281C100.233 6.18281 100.074 6.29598 100.02 6.46297C99.965 6.63075 100.026 6.81357 100.17 6.91724L105.253 10.56L103.301 16.4688C103.246 16.6357 103.306 16.8194 103.449 16.9222C103.592 17.0259 103.787 17.0259 103.93 16.9222L108.999 13.2588L114.07 16.9222C114.213 17.0259 114.407 17.0259 114.551 16.9222C114.694 16.8194 114.754 16.6357 114.699 16.4688L112.747 10.56L117.83 6.91724C117.974 6.81357 118.035 6.63074 117.98 6.46297C117.925 6.29598 117.767 6.18281 117.59 6.1836L111.317 6.19626L109.388 0.281267V0.280476C109.332 0.107155 109.165 -0.00681505 108.982 0.000316578L108.982 0.000317737Z"
                fill="#AB192D"
              />
            </svg>
            <p class="small-text" v-html="$t('testimonial_2')"></p>
          </div>
        </div>

        <div class="carousel-cell">
          <div class="left">
            <img data-flickity-lazyload="/img/testimonial2.webp" width="300" height="305" alt="Joeurs de Golf" />
          </div>
          <div class="right">
            <svg height="12" viewBox="0 0 118 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.98156 0.000317737C8.81058 0.00744045 8.66197 0.119031 8.60924 0.280477L6.68288 6.19547L0.410036 6.18281C0.23266 6.18281 0.0744666 6.29598 0.0201407 6.46297C-0.034989 6.63075 0.0257337 6.81357 0.169551 6.91724L5.25279 10.56L3.30086 16.4688C3.24573 16.6357 3.30565 16.8194 3.44947 16.9222C3.59249 17.0259 3.78743 17.0259 3.93047 16.9222L8.99918 13.2588L14.0695 16.9222C14.2126 17.0259 14.4075 17.0259 14.5505 16.9222C14.6943 16.8194 14.7543 16.6357 14.6991 16.4688L12.7472 10.56L17.8304 6.91724C17.9743 6.81357 18.035 6.63074 17.9799 6.46297C17.9247 6.29598 17.7673 6.18281 17.59 6.1836L11.3171 6.19626L9.38837 0.281267V0.280476C9.33164 0.107155 9.16546 -0.00681505 8.98168 0.000316578L8.98156 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.9816 0.000317737C33.8106 0.00744045 33.662 0.119031 33.6092 0.280477L31.6829 6.19547L25.41 6.18281C25.2327 6.18281 25.0745 6.29598 25.0201 6.46297C24.965 6.63075 25.0257 6.81357 25.1696 6.91724L30.2528 10.56L28.3009 16.4688C28.2457 16.6357 28.3057 16.8194 28.4495 16.9222C28.5925 17.0259 28.7874 17.0259 28.9305 16.9222L33.9992 13.2588L39.0695 16.9222C39.2126 17.0259 39.4075 17.0259 39.5505 16.9222C39.6943 16.8194 39.7543 16.6357 39.6991 16.4688L37.7472 10.56L42.8304 6.91724C42.9743 6.81357 43.035 6.63074 42.9799 6.46297C42.9247 6.29598 42.7673 6.18281 42.59 6.1836L36.3171 6.19626L34.3884 0.281267V0.280476C34.3316 0.107155 34.1655 -0.00681505 33.9817 0.000316578L33.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M58.9816 0.000317737C58.8106 0.00744045 58.662 0.119031 58.6092 0.280477L56.6829 6.19547L50.41 6.18281C50.2327 6.18281 50.0745 6.29598 50.0201 6.46297C49.965 6.63075 50.0257 6.81357 50.1696 6.91724L55.2528 10.56L53.3009 16.4688C53.2457 16.6357 53.3057 16.8194 53.4495 16.9222C53.5925 17.0259 53.7874 17.0259 53.9305 16.9222L58.9992 13.2588L64.0695 16.9222C64.2126 17.0259 64.4075 17.0259 64.5505 16.9222C64.6943 16.8194 64.7543 16.6357 64.6991 16.4688L62.7472 10.56L67.8304 6.91724C67.9743 6.81357 68.035 6.63074 67.9799 6.46297C67.9247 6.29598 67.7673 6.18281 67.59 6.1836L61.3171 6.19626L59.3884 0.281267V0.280476C59.3316 0.107155 59.1655 -0.00681505 58.9817 0.000316578L58.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M83.9816 0.000317737C83.8106 0.00744045 83.662 0.119031 83.6092 0.280477L81.6829 6.19547L75.41 6.18281C75.2327 6.18281 75.0745 6.29598 75.0201 6.46297C74.965 6.63075 75.0257 6.81357 75.1696 6.91724L80.2528 10.56L78.3009 16.4688C78.2457 16.6357 78.3057 16.8194 78.4495 16.9222C78.5925 17.0259 78.7874 17.0259 78.9305 16.9222L83.9992 13.2588L89.0695 16.9222C89.2126 17.0259 89.4075 17.0259 89.5505 16.9222C89.6943 16.8194 89.7543 16.6357 89.6991 16.4688L87.7472 10.56L92.8304 6.91724C92.9743 6.81357 93.035 6.63074 92.9799 6.46297C92.9247 6.29598 92.7673 6.18281 92.59 6.1836L86.3171 6.19626L84.3884 0.281267V0.280476C84.3316 0.107155 84.1655 -0.00681505 83.9817 0.000316578L83.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.982 0.000317737C108.811 0.00744045 108.662 0.119031 108.609 0.280477L106.683 6.19547L100.41 6.18281C100.233 6.18281 100.074 6.29598 100.02 6.46297C99.965 6.63075 100.026 6.81357 100.17 6.91724L105.253 10.56L103.301 16.4688C103.246 16.6357 103.306 16.8194 103.449 16.9222C103.592 17.0259 103.787 17.0259 103.93 16.9222L108.999 13.2588L114.07 16.9222C114.213 17.0259 114.407 17.0259 114.551 16.9222C114.694 16.8194 114.754 16.6357 114.699 16.4688L112.747 10.56L117.83 6.91724C117.974 6.81357 118.035 6.63074 117.98 6.46297C117.925 6.29598 117.767 6.18281 117.59 6.1836L111.317 6.19626L109.388 0.281267V0.280476C109.332 0.107155 109.165 -0.00681505 108.982 0.000316578L108.982 0.000317737Z"
                fill="#AB192D"
              />
            </svg>
            <p class="small-text" v-html="$t('testimonial_3')"></p>
          </div>
        </div>

        <div class="carousel-cell">
          <div class="left">
            <img data-flickity-lazyload="/img/testimonial3.webp" width="300" height="305" alt="Joeurs de Golf" />
          </div>
          <div class="right">
            <svg height="12" viewBox="0 0 118 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.98156 0.000317737C8.81058 0.00744045 8.66197 0.119031 8.60924 0.280477L6.68288 6.19547L0.410036 6.18281C0.23266 6.18281 0.0744666 6.29598 0.0201407 6.46297C-0.034989 6.63075 0.0257337 6.81357 0.169551 6.91724L5.25279 10.56L3.30086 16.4688C3.24573 16.6357 3.30565 16.8194 3.44947 16.9222C3.59249 17.0259 3.78743 17.0259 3.93047 16.9222L8.99918 13.2588L14.0695 16.9222C14.2126 17.0259 14.4075 17.0259 14.5505 16.9222C14.6943 16.8194 14.7543 16.6357 14.6991 16.4688L12.7472 10.56L17.8304 6.91724C17.9743 6.81357 18.035 6.63074 17.9799 6.46297C17.9247 6.29598 17.7673 6.18281 17.59 6.1836L11.3171 6.19626L9.38837 0.281267V0.280476C9.33164 0.107155 9.16546 -0.00681505 8.98168 0.000316578L8.98156 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.9816 0.000317737C33.8106 0.00744045 33.662 0.119031 33.6092 0.280477L31.6829 6.19547L25.41 6.18281C25.2327 6.18281 25.0745 6.29598 25.0201 6.46297C24.965 6.63075 25.0257 6.81357 25.1696 6.91724L30.2528 10.56L28.3009 16.4688C28.2457 16.6357 28.3057 16.8194 28.4495 16.9222C28.5925 17.0259 28.7874 17.0259 28.9305 16.9222L33.9992 13.2588L39.0695 16.9222C39.2126 17.0259 39.4075 17.0259 39.5505 16.9222C39.6943 16.8194 39.7543 16.6357 39.6991 16.4688L37.7472 10.56L42.8304 6.91724C42.9743 6.81357 43.035 6.63074 42.9799 6.46297C42.9247 6.29598 42.7673 6.18281 42.59 6.1836L36.3171 6.19626L34.3884 0.281267V0.280476C34.3316 0.107155 34.1655 -0.00681505 33.9817 0.000316578L33.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M58.9816 0.000317737C58.8106 0.00744045 58.662 0.119031 58.6092 0.280477L56.6829 6.19547L50.41 6.18281C50.2327 6.18281 50.0745 6.29598 50.0201 6.46297C49.965 6.63075 50.0257 6.81357 50.1696 6.91724L55.2528 10.56L53.3009 16.4688C53.2457 16.6357 53.3057 16.8194 53.4495 16.9222C53.5925 17.0259 53.7874 17.0259 53.9305 16.9222L58.9992 13.2588L64.0695 16.9222C64.2126 17.0259 64.4075 17.0259 64.5505 16.9222C64.6943 16.8194 64.7543 16.6357 64.6991 16.4688L62.7472 10.56L67.8304 6.91724C67.9743 6.81357 68.035 6.63074 67.9799 6.46297C67.9247 6.29598 67.7673 6.18281 67.59 6.1836L61.3171 6.19626L59.3884 0.281267V0.280476C59.3316 0.107155 59.1655 -0.00681505 58.9817 0.000316578L58.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M83.9816 0.000317737C83.8106 0.00744045 83.662 0.119031 83.6092 0.280477L81.6829 6.19547L75.41 6.18281C75.2327 6.18281 75.0745 6.29598 75.0201 6.46297C74.965 6.63075 75.0257 6.81357 75.1696 6.91724L80.2528 10.56L78.3009 16.4688C78.2457 16.6357 78.3057 16.8194 78.4495 16.9222C78.5925 17.0259 78.7874 17.0259 78.9305 16.9222L83.9992 13.2588L89.0695 16.9222C89.2126 17.0259 89.4075 17.0259 89.5505 16.9222C89.6943 16.8194 89.7543 16.6357 89.6991 16.4688L87.7472 10.56L92.8304 6.91724C92.9743 6.81357 93.035 6.63074 92.9799 6.46297C92.9247 6.29598 92.7673 6.18281 92.59 6.1836L86.3171 6.19626L84.3884 0.281267V0.280476C84.3316 0.107155 84.1655 -0.00681505 83.9817 0.000316578L83.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.982 0.000317737C108.811 0.00744045 108.662 0.119031 108.609 0.280477L106.683 6.19547L100.41 6.18281C100.233 6.18281 100.074 6.29598 100.02 6.46297C99.965 6.63075 100.026 6.81357 100.17 6.91724L105.253 10.56L103.301 16.4688C103.246 16.6357 103.306 16.8194 103.449 16.9222C103.592 17.0259 103.787 17.0259 103.93 16.9222L108.999 13.2588L114.07 16.9222C114.213 17.0259 114.407 17.0259 114.551 16.9222C114.694 16.8194 114.754 16.6357 114.699 16.4688L112.747 10.56L117.83 6.91724C117.974 6.81357 118.035 6.63074 117.98 6.46297C117.925 6.29598 117.767 6.18281 117.59 6.1836L111.317 6.19626L109.388 0.281267V0.280476C109.332 0.107155 109.165 -0.00681505 108.982 0.000316578L108.982 0.000317737Z"
                fill="#AB192D"
              />
            </svg>
            <p class="small-text" v-html="$t('testimonial_4')"></p>
          </div>
        </div>
        
        <div class="carousel-cell">
          <div class="left">
            <img data-flickity-lazyload="/img/testimonial4.webp" width="300" height="305" alt="Joeurs de Golf" />
          </div>
          <div class="right">
            <svg height="12" viewBox="0 0 118 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.98156 0.000317737C8.81058 0.00744045 8.66197 0.119031 8.60924 0.280477L6.68288 6.19547L0.410036 6.18281C0.23266 6.18281 0.0744666 6.29598 0.0201407 6.46297C-0.034989 6.63075 0.0257337 6.81357 0.169551 6.91724L5.25279 10.56L3.30086 16.4688C3.24573 16.6357 3.30565 16.8194 3.44947 16.9222C3.59249 17.0259 3.78743 17.0259 3.93047 16.9222L8.99918 13.2588L14.0695 16.9222C14.2126 17.0259 14.4075 17.0259 14.5505 16.9222C14.6943 16.8194 14.7543 16.6357 14.6991 16.4688L12.7472 10.56L17.8304 6.91724C17.9743 6.81357 18.035 6.63074 17.9799 6.46297C17.9247 6.29598 17.7673 6.18281 17.59 6.1836L11.3171 6.19626L9.38837 0.281267V0.280476C9.33164 0.107155 9.16546 -0.00681505 8.98168 0.000316578L8.98156 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.9816 0.000317737C33.8106 0.00744045 33.662 0.119031 33.6092 0.280477L31.6829 6.19547L25.41 6.18281C25.2327 6.18281 25.0745 6.29598 25.0201 6.46297C24.965 6.63075 25.0257 6.81357 25.1696 6.91724L30.2528 10.56L28.3009 16.4688C28.2457 16.6357 28.3057 16.8194 28.4495 16.9222C28.5925 17.0259 28.7874 17.0259 28.9305 16.9222L33.9992 13.2588L39.0695 16.9222C39.2126 17.0259 39.4075 17.0259 39.5505 16.9222C39.6943 16.8194 39.7543 16.6357 39.6991 16.4688L37.7472 10.56L42.8304 6.91724C42.9743 6.81357 43.035 6.63074 42.9799 6.46297C42.9247 6.29598 42.7673 6.18281 42.59 6.1836L36.3171 6.19626L34.3884 0.281267V0.280476C34.3316 0.107155 34.1655 -0.00681505 33.9817 0.000316578L33.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M58.9816 0.000317737C58.8106 0.00744045 58.662 0.119031 58.6092 0.280477L56.6829 6.19547L50.41 6.18281C50.2327 6.18281 50.0745 6.29598 50.0201 6.46297C49.965 6.63075 50.0257 6.81357 50.1696 6.91724L55.2528 10.56L53.3009 16.4688C53.2457 16.6357 53.3057 16.8194 53.4495 16.9222C53.5925 17.0259 53.7874 17.0259 53.9305 16.9222L58.9992 13.2588L64.0695 16.9222C64.2126 17.0259 64.4075 17.0259 64.5505 16.9222C64.6943 16.8194 64.7543 16.6357 64.6991 16.4688L62.7472 10.56L67.8304 6.91724C67.9743 6.81357 68.035 6.63074 67.9799 6.46297C67.9247 6.29598 67.7673 6.18281 67.59 6.1836L61.3171 6.19626L59.3884 0.281267V0.280476C59.3316 0.107155 59.1655 -0.00681505 58.9817 0.000316578L58.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M83.9816 0.000317737C83.8106 0.00744045 83.662 0.119031 83.6092 0.280477L81.6829 6.19547L75.41 6.18281C75.2327 6.18281 75.0745 6.29598 75.0201 6.46297C74.965 6.63075 75.0257 6.81357 75.1696 6.91724L80.2528 10.56L78.3009 16.4688C78.2457 16.6357 78.3057 16.8194 78.4495 16.9222C78.5925 17.0259 78.7874 17.0259 78.9305 16.9222L83.9992 13.2588L89.0695 16.9222C89.2126 17.0259 89.4075 17.0259 89.5505 16.9222C89.6943 16.8194 89.7543 16.6357 89.6991 16.4688L87.7472 10.56L92.8304 6.91724C92.9743 6.81357 93.035 6.63074 92.9799 6.46297C92.9247 6.29598 92.7673 6.18281 92.59 6.1836L86.3171 6.19626L84.3884 0.281267V0.280476C84.3316 0.107155 84.1655 -0.00681505 83.9817 0.000316578L83.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.982 0.000317737C108.811 0.00744045 108.662 0.119031 108.609 0.280477L106.683 6.19547L100.41 6.18281C100.233 6.18281 100.074 6.29598 100.02 6.46297C99.965 6.63075 100.026 6.81357 100.17 6.91724L105.253 10.56L103.301 16.4688C103.246 16.6357 103.306 16.8194 103.449 16.9222C103.592 17.0259 103.787 17.0259 103.93 16.9222L108.999 13.2588L114.07 16.9222C114.213 17.0259 114.407 17.0259 114.551 16.9222C114.694 16.8194 114.754 16.6357 114.699 16.4688L112.747 10.56L117.83 6.91724C117.974 6.81357 118.035 6.63074 117.98 6.46297C117.925 6.29598 117.767 6.18281 117.59 6.1836L111.317 6.19626L109.388 0.281267V0.280476C109.332 0.107155 109.165 -0.00681505 108.982 0.000316578L108.982 0.000317737Z"
                fill="#AB192D"
              />
            </svg>
            <p class="small-text" v-html="$t('testimonial_5')"></p>
          </div>
        </div>
        
        <div class="carousel-cell">
          <div class="left">
            <img data-flickity-lazyload="/img/testimonial5.webp" width="300" height="305" alt="Joeurs de Golf" />
          </div>
          <div class="right">
            <svg height="12" viewBox="0 0 118 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.98156 0.000317737C8.81058 0.00744045 8.66197 0.119031 8.60924 0.280477L6.68288 6.19547L0.410036 6.18281C0.23266 6.18281 0.0744666 6.29598 0.0201407 6.46297C-0.034989 6.63075 0.0257337 6.81357 0.169551 6.91724L5.25279 10.56L3.30086 16.4688C3.24573 16.6357 3.30565 16.8194 3.44947 16.9222C3.59249 17.0259 3.78743 17.0259 3.93047 16.9222L8.99918 13.2588L14.0695 16.9222C14.2126 17.0259 14.4075 17.0259 14.5505 16.9222C14.6943 16.8194 14.7543 16.6357 14.6991 16.4688L12.7472 10.56L17.8304 6.91724C17.9743 6.81357 18.035 6.63074 17.9799 6.46297C17.9247 6.29598 17.7673 6.18281 17.59 6.1836L11.3171 6.19626L9.38837 0.281267V0.280476C9.33164 0.107155 9.16546 -0.00681505 8.98168 0.000316578L8.98156 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.9816 0.000317737C33.8106 0.00744045 33.662 0.119031 33.6092 0.280477L31.6829 6.19547L25.41 6.18281C25.2327 6.18281 25.0745 6.29598 25.0201 6.46297C24.965 6.63075 25.0257 6.81357 25.1696 6.91724L30.2528 10.56L28.3009 16.4688C28.2457 16.6357 28.3057 16.8194 28.4495 16.9222C28.5925 17.0259 28.7874 17.0259 28.9305 16.9222L33.9992 13.2588L39.0695 16.9222C39.2126 17.0259 39.4075 17.0259 39.5505 16.9222C39.6943 16.8194 39.7543 16.6357 39.6991 16.4688L37.7472 10.56L42.8304 6.91724C42.9743 6.81357 43.035 6.63074 42.9799 6.46297C42.9247 6.29598 42.7673 6.18281 42.59 6.1836L36.3171 6.19626L34.3884 0.281267V0.280476C34.3316 0.107155 34.1655 -0.00681505 33.9817 0.000316578L33.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M58.9816 0.000317737C58.8106 0.00744045 58.662 0.119031 58.6092 0.280477L56.6829 6.19547L50.41 6.18281C50.2327 6.18281 50.0745 6.29598 50.0201 6.46297C49.965 6.63075 50.0257 6.81357 50.1696 6.91724L55.2528 10.56L53.3009 16.4688C53.2457 16.6357 53.3057 16.8194 53.4495 16.9222C53.5925 17.0259 53.7874 17.0259 53.9305 16.9222L58.9992 13.2588L64.0695 16.9222C64.2126 17.0259 64.4075 17.0259 64.5505 16.9222C64.6943 16.8194 64.7543 16.6357 64.6991 16.4688L62.7472 10.56L67.8304 6.91724C67.9743 6.81357 68.035 6.63074 67.9799 6.46297C67.9247 6.29598 67.7673 6.18281 67.59 6.1836L61.3171 6.19626L59.3884 0.281267V0.280476C59.3316 0.107155 59.1655 -0.00681505 58.9817 0.000316578L58.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M83.9816 0.000317737C83.8106 0.00744045 83.662 0.119031 83.6092 0.280477L81.6829 6.19547L75.41 6.18281C75.2327 6.18281 75.0745 6.29598 75.0201 6.46297C74.965 6.63075 75.0257 6.81357 75.1696 6.91724L80.2528 10.56L78.3009 16.4688C78.2457 16.6357 78.3057 16.8194 78.4495 16.9222C78.5925 17.0259 78.7874 17.0259 78.9305 16.9222L83.9992 13.2588L89.0695 16.9222C89.2126 17.0259 89.4075 17.0259 89.5505 16.9222C89.6943 16.8194 89.7543 16.6357 89.6991 16.4688L87.7472 10.56L92.8304 6.91724C92.9743 6.81357 93.035 6.63074 92.9799 6.46297C92.9247 6.29598 92.7673 6.18281 92.59 6.1836L86.3171 6.19626L84.3884 0.281267V0.280476C84.3316 0.107155 84.1655 -0.00681505 83.9817 0.000316578L83.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.982 0.000317737C108.811 0.00744045 108.662 0.119031 108.609 0.280477L106.683 6.19547L100.41 6.18281C100.233 6.18281 100.074 6.29598 100.02 6.46297C99.965 6.63075 100.026 6.81357 100.17 6.91724L105.253 10.56L103.301 16.4688C103.246 16.6357 103.306 16.8194 103.449 16.9222C103.592 17.0259 103.787 17.0259 103.93 16.9222L108.999 13.2588L114.07 16.9222C114.213 17.0259 114.407 17.0259 114.551 16.9222C114.694 16.8194 114.754 16.6357 114.699 16.4688L112.747 10.56L117.83 6.91724C117.974 6.81357 118.035 6.63074 117.98 6.46297C117.925 6.29598 117.767 6.18281 117.59 6.1836L111.317 6.19626L109.388 0.281267V0.280476C109.332 0.107155 109.165 -0.00681505 108.982 0.000316578L108.982 0.000317737Z"
                fill="#AB192D"
              />
            </svg>
            <p class="small-text" v-html="$t('testimonial_6')"></p>
          </div>
        </div>
        
        <div class="carousel-cell">
          <div class="left">
            <img data-flickity-lazyload="/img/testimonial6.webp" width="300" height="305" alt="Joeurs de Golf" />
          </div>
          <div class="right">
            <svg height="12" viewBox="0 0 118 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.98156 0.000317737C8.81058 0.00744045 8.66197 0.119031 8.60924 0.280477L6.68288 6.19547L0.410036 6.18281C0.23266 6.18281 0.0744666 6.29598 0.0201407 6.46297C-0.034989 6.63075 0.0257337 6.81357 0.169551 6.91724L5.25279 10.56L3.30086 16.4688C3.24573 16.6357 3.30565 16.8194 3.44947 16.9222C3.59249 17.0259 3.78743 17.0259 3.93047 16.9222L8.99918 13.2588L14.0695 16.9222C14.2126 17.0259 14.4075 17.0259 14.5505 16.9222C14.6943 16.8194 14.7543 16.6357 14.6991 16.4688L12.7472 10.56L17.8304 6.91724C17.9743 6.81357 18.035 6.63074 17.9799 6.46297C17.9247 6.29598 17.7673 6.18281 17.59 6.1836L11.3171 6.19626L9.38837 0.281267V0.280476C9.33164 0.107155 9.16546 -0.00681505 8.98168 0.000316578L8.98156 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.9816 0.000317737C33.8106 0.00744045 33.662 0.119031 33.6092 0.280477L31.6829 6.19547L25.41 6.18281C25.2327 6.18281 25.0745 6.29598 25.0201 6.46297C24.965 6.63075 25.0257 6.81357 25.1696 6.91724L30.2528 10.56L28.3009 16.4688C28.2457 16.6357 28.3057 16.8194 28.4495 16.9222C28.5925 17.0259 28.7874 17.0259 28.9305 16.9222L33.9992 13.2588L39.0695 16.9222C39.2126 17.0259 39.4075 17.0259 39.5505 16.9222C39.6943 16.8194 39.7543 16.6357 39.6991 16.4688L37.7472 10.56L42.8304 6.91724C42.9743 6.81357 43.035 6.63074 42.9799 6.46297C42.9247 6.29598 42.7673 6.18281 42.59 6.1836L36.3171 6.19626L34.3884 0.281267V0.280476C34.3316 0.107155 34.1655 -0.00681505 33.9817 0.000316578L33.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M58.9816 0.000317737C58.8106 0.00744045 58.662 0.119031 58.6092 0.280477L56.6829 6.19547L50.41 6.18281C50.2327 6.18281 50.0745 6.29598 50.0201 6.46297C49.965 6.63075 50.0257 6.81357 50.1696 6.91724L55.2528 10.56L53.3009 16.4688C53.2457 16.6357 53.3057 16.8194 53.4495 16.9222C53.5925 17.0259 53.7874 17.0259 53.9305 16.9222L58.9992 13.2588L64.0695 16.9222C64.2126 17.0259 64.4075 17.0259 64.5505 16.9222C64.6943 16.8194 64.7543 16.6357 64.6991 16.4688L62.7472 10.56L67.8304 6.91724C67.9743 6.81357 68.035 6.63074 67.9799 6.46297C67.9247 6.29598 67.7673 6.18281 67.59 6.1836L61.3171 6.19626L59.3884 0.281267V0.280476C59.3316 0.107155 59.1655 -0.00681505 58.9817 0.000316578L58.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M83.9816 0.000317737C83.8106 0.00744045 83.662 0.119031 83.6092 0.280477L81.6829 6.19547L75.41 6.18281C75.2327 6.18281 75.0745 6.29598 75.0201 6.46297C74.965 6.63075 75.0257 6.81357 75.1696 6.91724L80.2528 10.56L78.3009 16.4688C78.2457 16.6357 78.3057 16.8194 78.4495 16.9222C78.5925 17.0259 78.7874 17.0259 78.9305 16.9222L83.9992 13.2588L89.0695 16.9222C89.2126 17.0259 89.4075 17.0259 89.5505 16.9222C89.6943 16.8194 89.7543 16.6357 89.6991 16.4688L87.7472 10.56L92.8304 6.91724C92.9743 6.81357 93.035 6.63074 92.9799 6.46297C92.9247 6.29598 92.7673 6.18281 92.59 6.1836L86.3171 6.19626L84.3884 0.281267V0.280476C84.3316 0.107155 84.1655 -0.00681505 83.9817 0.000316578L83.9816 0.000317737Z"
                fill="#AB192D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.982 0.000317737C108.811 0.00744045 108.662 0.119031 108.609 0.280477L106.683 6.19547L100.41 6.18281C100.233 6.18281 100.074 6.29598 100.02 6.46297C99.965 6.63075 100.026 6.81357 100.17 6.91724L105.253 10.56L103.301 16.4688C103.246 16.6357 103.306 16.8194 103.449 16.9222C103.592 17.0259 103.787 17.0259 103.93 16.9222L108.999 13.2588L114.07 16.9222C114.213 17.0259 114.407 17.0259 114.551 16.9222C114.694 16.8194 114.754 16.6357 114.699 16.4688L112.747 10.56L117.83 6.91724C117.974 6.81357 118.035 6.63074 117.98 6.46297C117.925 6.29598 117.767 6.18281 117.59 6.1836L111.317 6.19626L109.388 0.281267V0.280476C109.332 0.107155 109.165 -0.00681505 108.982 0.000316578L108.982 0.000317737Z"
                fill="#AB192D"
              />
            </svg>
            <p class="small-text" v-html="$t('testimonial_7')"></p>
          </div>
        </div>

      </div>
    </section>

    <!-- Split -->
    <section class="split site-max-width">
      <div class="left" data-inview="fadeInUp" data-delay="100">
        <img loading="lazy" src="@/assets/img/ecoresponsabilite-2.webp" width="648" height="453" alt="Sommet Tremblant avec logos ecoresponsables" />
      </div>
      <div class="right">
        <h3 class="regular-title" data-inview="fadeInUp" data-delay="200">
          {{ $t('eco_title') }}
        </h3>
        <p class="regular-text" data-inview="fadeInUp" data-delay="300">
          {{ $t('eco_sub_title') }}
        </p>
        <p class="regular-text" data-inview="fadeInUp" data-delay="400">
          {{ $t('eco_desc') }}
        </p>
        <a
          :href="$t('eco_cta_link')"
          target="_blank"
          class="btn is-alternate"
          data-inview="fadeInUp"
          data-delay="400"
          >{{ $t('eco_cta') }}</a
        >
      </div>
    </section>

    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Flickity from 'flickity'
import { gsap } from 'gsap'
import GLightbox from 'glightbox'

import { mapState } from 'vuex'

import initInViewAnimations from '@/misc/in-view-animations'

// import AlertBanner from '@/components/AlertBanner'
import Header from '@/components/Header'
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'

import SvgConference from '@/components/svgs/SvgConference'
import SvgActivity from '@/components/svgs/SvgActivity'
import SvgTeam from '@/components/svgs/SvgTeam'

require('flickity-imagesloaded')

export default {
  name: 'Home',

  head() {
    return {
      title: {
        inner: this.$t('og_title'),
      },
      meta: [
        {
          name: 'description',
          content: this.$t('og_desc'),
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: this.$t('og_title') },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: this.$t('og_desc'),
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t('og_title') },
        { property: 'og:site_name', content: this.$t('og_title') },
        {
          property: 'og:description',
          content: this.$t('og_desc'),
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://firebasestorage.googleapis.com/v0/b/tremblant-infra.appspot.com/o/hero.webp?alt=media&token=29e07b5d-9a11-4702-89bf-3d56d06fc310',
        },
        {
          name: 'twitter:image',
          content:
            'https://firebasestorage.googleapis.com/v0/b/tremblant-infra.appspot.com/o/hero.webp?alt=media&token=29e07b5d-9a11-4702-89bf-3d56d06fc310',
        },
      ],
    }
  },

  components: {
    // AlertBanner,
    Header,
    NavBar,
    SvgConference,
    SvgActivity,
    SvgTeam,
    Footer,
  },

  data() {
    return {
      flk: null,
      heroFlk: null,
      expFlk: null,
      testimonialsFlk: null,
      selectedTransport: null,
    }
  },

  computed: mapState('app', ['appTitle']),

  mounted() {
    const sliders = ['.slider-carousel.simple1', '.slider-carousel.simple2']
    sliders.forEach((selector) => {
      const slider = document.querySelector(selector)
      const flk = new Flickity(slider, {
        // options
        cellAlign: 'center',
        wrapAround: true,
        autoPlay: false,
        imagesLoaded: true,
        lazyLoad: 1,
        pageDots: false,
        prevNextButtons: true,
      })
      this.flk = flk
    })
    this.testimonialsFlk = new Flickity(document.querySelector('.testimonials-carousel'), {
      // options
      cellAlign: 'left',
      wrapAround: true,
      autoPlay: false,
      imagesLoaded: true,
      lazyLoad: true,
      pageDots: true,
      prevNextButtons: false,
    })
    this.heroFlk = new Flickity(document.querySelector('.carousel-wrapper'), {
      // options
      cellAlign: 'center',
      wrapAround: true,
      autoPlay: 4375,
      imagesLoaded: true,
      lazyLoad: true,
      pageDots: false,
      prevNextButtons: true,
    })
    this.expFlk = new Flickity(document.querySelector('.exp-carousel'), {
      // options
      cellAlign: 'center',
      wrapAround: true,
      autoPlay: 4375,
      imagesLoaded: true,
      lazyLoad: 1,
      pageDots: true,
      prevNextButtons: true,
    })

    // GLightbox
    this.lightboxelement = GLightbox
    GLightbox()

    // Page load buffer
    setTimeout(() => {
      initInViewAnimations()

      gsap.to('.scroller-left', {
        duration: 300,
        delay: 0,
        y: '-85%',
        ease: 'linear',
      })

      gsap.to('.scroller-right', {
        duration: 300,
        delay: 0,
        y: '85%',
        ease: 'linear',
      })

      gsap.to('.line1', { x: '-150vw', duration: 40, ease: 'none', repeat: -1 })

    }, 155)

    setTimeout(() => {
      if (this.$route && this.$route.hash) {
        const selector = this.$route.hash.replace('a-', '')

        const $zone = document.querySelector(selector)

        const topPos = $zone.getBoundingClientRect().top + window.scrollY

        // this changes the scrolling behavior to "smooth"
        window.scroll({
          top: topPos - 80,
          behavior: 'smooth',
        })
      }
    }, 1624)
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.play-btn {
  position: absolute;
  bottom: 5vw;
  right: 5%;
  z-index: 5;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(0.9);
  }

  @media (max-width: 768px) {
    transform: scale(0.8);
    bottom: 10px;
    right: 10px;

    &:hover {
      transform: scale(0.8);
    }
  }
}
</style>
