// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore, doc, collection, getDocs, limit, setDoc } from 'firebase/firestore'
import { getStorage, ref, uploadBytes } from 'firebase/storage'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD15E6FnBulMSGu5-EuCsRyTzRegeuTxQA',
  authDomain: 'tremblant-infra.firebaseapp.com',
  projectId: 'tremblant-infra',
  storageBucket: 'tremblant-infra.appspot.com',
  messagingSenderId: '1078045170507',
  appId: '1:1078045170507:web:52a0688b71b3b96a2166ce',
  measurementId: 'G-L40GPVJG1B',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const storage = getStorage()
// const analytics = getAnalytics(app)

// Get a list of cities from your database
async function saveForm(form) {
  try {
    let currentId = 0

    const docConfig = await getDocs(collection(db, 'configs'), limit(1))
    docConfig.forEach((item) => {
      if (item.id === 'data') {
        currentId = item.data().currentId
      }
    })

    if (!currentId) {
      const fallback = await getDocs(collection(db, 'configs'), limit(1))
      fallback.forEach((item) => {
        if (item.id === 'data') {
          currentId = item.data().currentId
        }
      })
    }

    currentId += 1

    await setDoc(doc(db, 'forms', `${currentId}`), { id: `${currentId}`, ...form })
    await setDoc(doc(db, 'configs', 'data'), { currentId })
    
    return currentId
  } catch (e) {
    console.error('Error adding document: ', e)
    return false
  }
}

async function uploadFile(file) {
  // 'file' comes from the Blob or File API
  try {
    // This may throw an error
    const fileName = file.name.replace(/[^a-z0-9.]/gi, '').toLowerCase()
    const storageRef = ref(storage, `forms-files/${fileName}`)
    const snapshot = await uploadBytes(storageRef, file)
    console.log('Uploaded a blob or file!', snapshot)
    return snapshot
  } catch (e) {
    return e
  }
}

export { saveForm, uploadFile }
