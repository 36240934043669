<template>
  <svg width="202" height="80" viewBox="0 0 202 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 39.9474C1 18.4379 45.7701 1 101.001 1C156.228 1 201 18.4379 201 39.9474C201 61.457 156.228 78.8995 101.001 78.8995C45.7701 78.8995 1 61.457 1 39.9474Z"
      fill="#BF3038"
    />
    <path
      d="M1 39.9474C1 18.4379 45.7701 1 101.001 1C156.228 1 201 18.4379 201 39.9474C201 61.457 156.228 78.8994 101.001 78.8994C45.7701 78.8994 1 61.457 1 39.9474Z"
      stroke="#FEFEFE"
      stroke-width="1.27"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M142.851 45.3775C149.043 56.0883 153.875 63.0398 162.134 57.1408L162.001 56.8268C155.029 58.824 148.454 46.1676 144.14 38.8264L135.813 24.6064L133.281 29.0512C133.281 29.0512 140.975 42.1262 142.851 45.3775Z"
      fill="#FEFEFE"
    />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M122.245 48.4592L116.278 58.8455H118.841L124.787 48.4744L122.245 48.4592Z" fill="#FEFEFE" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M131.815 31.6106L123.244 46.5281L125.79 46.5402L130.573 38.2527L138.819 52.3225C144.468 61.9096 149.478 70.5247 158.135 64.1495L158.003 63.8295C150.92 66.0147 144.052 52.4742 140.142 45.8336L131.815 31.6106Z"
      fill="#FEFEFE"
    />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1909 31.2811H24.6757V48.5628H29.064V31.2811H34.5503V29.3264H19.1909V31.2811Z" fill="#FEFEFE" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.814 39.0594L47.6733 48.5631H52.9553C52.9553 48.5631 46.389 40.353 45.519 39.0594C52.4241 37.6855 52.3339 29.3252 45.4746 29.3252H36.4419V48.5631H40.8332V30.8599C40.8332 30.8599 41.8408 30.8629 42.8691 30.8599C47.9707 30.8417 47.2516 38.2557 40.8066 37.7962L40.814 39.0594Z"
      fill="#FEFEFE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M55.0664 48.5628H66.5892V46.5019H59.4533V39.3124H66.4516V37.4107C66.4516 37.4107 59.4533 37.4653 59.4533 37.4107V31.2811H66.7239V29.3264H55.0664V48.5628Z"
      fill="#FEFEFE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M72.787 48.5798L72.8077 35.9871L79.047 48.5404H80.8994L87.3385 35.7217L87.334 48.5632H92.0065L92.0021 29.2737C92.0021 29.2737 88.4511 29.2873 88.4348 29.2737L80.969 43.9137L73.6333 29.3268H70.6135L70.5928 48.5798H72.787Z"
      fill="#FEFEFE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M96.7612 48.5628H104.786C111.987 48.5628 114.728 39.3503 106.088 38.3115C111.573 38.2251 113.218 29.3264 105.332 29.3264H96.7612V48.5628ZM102.418 39.2623C108.451 39.2623 107.939 46.9265 103.209 46.9265H101.046V30.8595H103.484C107.737 30.8595 107.29 37.6306 102.418 37.623H101.037V39.2714L102.418 39.2623Z"
      fill="#FEFEFE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M114.956 48.5628H132.268V46.417C132.268 46.417 119.387 46.3973 119.387 46.414C119.387 46.464 119.391 29.3264 119.391 29.3264H114.956V48.5628Z"
      fill="#FEFEFE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M151.532 48.5628H153.932V34.9798L167.028 48.6674H169.499V29.3264H167.167V42.4333L154.48 29.3264H151.532V48.5628Z"
      fill="#FEFEFE"
    />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M171.571 31.2811H177.06V48.5628H181.447V31.2811H186.932V29.3264H171.571V31.2811Z" fill="#FEFEFE" />
  </svg>
</template>

<script>
export default {
  name: 'Logo',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
