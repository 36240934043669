<template>
  <header class="header-ctas" :class="forceClass">
    <div class="left">
      <router-link class="logo" :to="{ name: $route.meta.lang === 'fr' ? 'home' : 'home-en' }"><Logo /></router-link>
    </div>

    <div class="right">
      <span class="small-text hours">
        <span class="v-align">
          <strong>{{ $t('open_note') }}</strong>
          <span>{{ $t('open_hours') }}</span>
        </span>
      </span>
      
      <a href="tel:18662146940" class="tel"><span class="v-align">1-866-214-6940</span></a>
      <span class="sep"><span class="v-align">|</span></span>

      <router-link :to="{ name: $route.name + '-en' }" class="lang" v-if="$route.meta.lang === 'fr'"><span class="v-align">EN</span></router-link>
      <router-link :to="{ name: $route.name.replace('-en', '') }" class="lang" v-if="$route.meta.lang === 'en'"><span class="v-align">FR</span></router-link>

      <router-link :to="{ name: 'soumission' }" v-if="$route.meta.lang === 'fr'" class="btn cta">{{ $t('cta') }}</router-link>
      <router-link :to="{ name: 'soumission-en' }" v-if="$route.meta.lang === 'en'" class="btn cta">{{ $t('cta') }}</router-link>
    </div>
  </header>
</template>

<script>
import Logo from '@/components/Logo'

export default {
  name: 'Header',

  props: {
    forceClass: {
      type: String,
      default: '',
      required: false,
    },
  },

  components: {
    Logo,
  },
}
</script>
