<template>
  <div class="page-wrapper">
    <!-- Alert Banner -->
    <!-- <AlertBanner /> -->

    <!-- Header ctas -->
    <Header />

    <!-- Hero -->
    <section class="hero">
      <div class="carousel-wrapper">
        <div class="carousel-cell">
          <img data-flickity-lazyload-src="/img/hero-offre-party-4.webp" alt="Un groupe d'entrepreneurs dans le rues de Tremblant" />
        </div>
        <div class="carousel-cell">
          <img data-flickity-lazyload-src="/img/hero-offre-party-2.webp" alt="Un groupe d'entrepreneurs dans le rues de Tremblant" />
        </div>
      </div>
      <div class="promotion_titles_wrap">
        <h1 class="big-title" v-html="$t('promo_hero_title')"></h1>
        <h2 class="medium-title price" v-html="$t('promo_hero_deal')"></h2>
      </div>
    </section>

    <!-- Site Nav -->
    <NavBar />

    <!-- Split Promotion -->
    <section class="split-promotion site-max-width">
      <div class="left">
        <img src="@/assets/img/offre-party-2023.webp" alt="Feux d'artifices à Tremblant" />
      </div>
      <div class="right" v-if="$route.meta.lang === 'fr'">
        <h3 class="big-title">Célébrez avec nous!</h3>
        <p class="regular-text">
          Prenez de l'avance en réservant dès maintenant votre party de bureau 2023 avec notre offre spéciale pour les groupes! Demandez une soumission pour votre prochain party de bureau dès maintenant.
        </p>
        <ul>
          <strong>Le forfait à partir de 210$ par personne inclut :</strong>
          <li>Menu 3 ou 4 services</li>
          <li>Une nuitée en occupation simple dans une chambre d'hôtel</li>
        </ul>
        <router-link :to="{ name: $route.meta.lang === 'fr' ? 'soumission' : 'soumission-en' }" class="btn cta" title="Demandez une soumission" aria-label="Lien vers la page du formulaire de soumission">{{ $t('cta') }}</router-link>
      </div>
      <div class="right" v-else-if="$route.meta.lang === 'en'">
        <h3 class="big-title">Come celebrate<br>with us!</h3>
        <p class="regular-text">
          Book your 2023 office party in advance with our special group offer! Request a proposal for your next office party now.
        </p>
        <ul>
          <strong>The package starting from $210 per person includes :</strong>
          <li>3 or 4 courses dining</li>
          <li>One night stay in one of our hotel rooms, single occupancy</li>
        </ul>
        <router-link :to="{ name: $route.meta.lang === 'fr' ? 'soumission' : 'soumission-en' }" class="btn cta" title="Demandez une soumission" aria-label="Lien vers la page du formulaire de soumission">{{ $t('cta') }}</router-link>
      </div>
    </section>
    <div class="modalites site-max-width">
      <div class="content">
        <ul v-if="$route.meta.lang === 'fr'">
          <strong>Modalités - Forfait nuitée avec souper :</strong>
          <li>Au Camp de Base: 3 ou 4 services</li>
          <li>Au Westin: 4 services en salle (groupes séjournant au Westin)</li>
          <li>Au Fairmont: Le Comptoir ou 4 services en salle (groupes séjournant au Fairmont)</li>
          <li>Valide du 22 octobre au 23 novembre 2023</li>
          <li>Séjour une nuit ou plus, dimanche au jeudi</li>
          <li>20 personnes et plus</li>
          <li>Prix "à partir de", par personne, en occupation simple</li>
          <li>Service, taxes et redevance en sus</li>
        </ul>
        <ul v-else-if="$route.meta.lang === 'en'">
          <strong>Terms and conditions - Package including night stay and dinner :</strong>
          <li>At Camp de Base Tremblant: 3 or 4-course dining</li>
          <li>At the Westin Tremblant : 4 room services (groups staying at the Westin)</li>
          <li>At the Fairmont Tremblant: Le Comptoir buffet or 4-course dining (groups staying at the Fairmont)</li>
          <li>Valid from October 22 to November 23, 2023</li>
          <li>One-night stay or more, from Sunday to Thursday</li>
          <li>20 people or more</li>
          <li>Price "starting from", per person, single occupancy</li>
          <li>Service, taxes and fees not included</li>
        </ul>
      </div>
    </div>

    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Flickity from 'flickity'

import { mapState } from 'vuex'

import Header from '@/components/Header'
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'

require('flickity-imagesloaded')

export default {
  name: 'Promotion',

  head() {
    return {
      title: {
        inner: 'Promotion | Offre pour entreprises | Événement corporative',
      },
      meta: [
        {
          name: 'description',
          content: 'Vous voulez organiser un événement corporatif, un colloque ou même un séminaire? Tremblant apportera votre compagnie au sommet!',
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: 'Offre pour entreprises | Événement corporative' },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Vous voulez organiser un événement corporatif, un colloque ou même un séminaire? Tremblant apportera votre compagnie au sommet!',
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Offre pour entreprises | Événement corporative' },
        { property: 'og:site_name', content: 'Offre pour entreprises | Événement corporative' },
        {
          property: 'og:description',
          content: 'Vous voulez organiser un événement corporatif, un colloque ou même un séminaire? Tremblant apportera votre compagnie au sommet!',
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://firebasestorage.googleapis.com/v0/b/tremblant-infra.appspot.com/o/hero.jpg?alt=media&token=29e07b5d-9a11-4702-89bf-3d56d06fc310',
        },
        {
          name: 'twitter:image',
          content:
            'https://firebasestorage.googleapis.com/v0/b/tremblant-infra.appspot.com/o/hero.jpg?alt=media&token=29e07b5d-9a11-4702-89bf-3d56d06fc310',
        },
      ],
    }
  },

  components: {
    Header,
    NavBar,
    Footer,
  },

  data() {
    return {
      heroFlk: null,
    }
  },

  computed: mapState('app', ['appTitle']),

  mounted() {
    this.heroFlk = new Flickity(document.querySelector('.carousel-wrapper'), {
      // options
      cellAlign: 'center',
      wrapAround: true,
      autoPlay: 4375,
      imagesLoaded: true,
      lazyLoad: 2,
      pageDots: false,
      prevNextButtons: true,
    })
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
