<template>
  <svg width="64" height="60" viewBox="0 0 64 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.9441 6.20947V8.50669C45.9328 10.3728 49.3161 13.3193 51.7138 16.9668H54.1774C51.3809 12.1869 47.0897 8.38097 41.9436 6.20954L41.9441 6.20947Z"
      fill="#2C444D"
    />
    <path
      d="M9.75317 42.8658C12.5524 47.6457 16.8409 51.4516 21.9897 53.6259V51.3286C18.001 49.4625 14.6178 46.516 12.2172 42.8657L9.75317 42.8658Z"
      fill="#2C444D"
    />
    <path
      d="M21.9897 6.20947C16.8442 8.38391 12.5525 12.1871 9.75317 16.9695H12.2168C14.6176 13.3191 18.0006 10.3726 21.9892 8.50941L21.9897 6.20947Z"
      fill="#2C444D"
    />
    <path
      d="M41.944 53.6258C47.0895 51.4514 51.3812 47.6482 54.1777 42.8657H51.7141C49.3133 46.5162 45.9303 49.4626 41.9417 51.3258L41.944 53.6258Z"
      fill="#2C444D"
    />
    <path
      d="M32.365 10.1955C34.7304 10.1955 36.6565 8.26931 36.6565 5.90393V4.29152C36.6565 1.92614 34.7304 0 32.365 0H31.5684C29.203 0 27.2769 1.92614 27.2769 4.29152V5.90393C27.2769 8.26931 29.203 10.1955 31.5684 10.1955H32.365ZM29.3721 5.90393V4.29152C29.3721 3.08016 30.357 2.0953 31.5683 2.0953H32.3649C33.5763 2.0953 34.5612 3.08023 34.5612 4.29152V5.90393C34.5612 7.11529 33.5762 8.10015 32.3649 8.10015H31.5683C30.3569 8.10015 29.3721 7.11522 29.3721 5.90393Z"
      fill="#2C444D"
    />
    <path
      d="M24.9142 15.84V19.4058H39.0162V15.84C39.0162 12.9372 36.6563 10.5771 33.7534 10.5771H30.1767C27.2765 10.5771 24.9138 12.9398 24.9138 15.84H24.9142ZM27.0095 15.84C27.0095 14.0939 28.4309 12.6724 30.177 12.6724H33.7538C35.4998 12.6724 36.9213 14.0939 36.9213 15.84V17.3105H27.0098L27.0095 15.84Z"
      fill="#2C444D"
    />
    <path
      d="M32.3652 40.4302H31.5686C29.8661 40.4302 28.3983 41.4287 27.7054 42.8665C27.4353 43.4285 27.2798 44.056 27.2798 44.719V46.3314C27.2798 48.6968 29.2059 50.6229 31.5713 50.6229H32.3679C34.7333 50.6229 36.6595 48.6968 36.6595 46.3314V44.719C36.6595 44.056 36.504 43.4285 36.2339 42.8665C35.5354 41.4287 34.0676 40.4302 32.3652 40.4302H32.3652ZM34.5614 46.3341C34.5614 47.5455 33.5765 48.5303 32.3652 48.5303H31.5686C30.3572 48.5303 29.3723 47.5454 29.3723 46.3341V44.7217C29.3723 43.5103 30.3573 42.5255 31.5686 42.5255H32.3652C33.5766 42.5255 34.5614 43.5104 34.5614 44.7217V46.3341Z"
      fill="#2C444D"
    />
    <path
      d="M24.9142 59.836H39.0162V56.2702C39.0162 53.3673 36.6563 51.0073 33.7534 51.0073H30.1767C27.2738 51.0073 24.9138 53.3673 24.9138 56.2702L24.9142 59.836ZM27.0095 56.2702C27.0095 54.5241 28.4309 53.1026 30.177 53.1026H33.7538C35.4998 53.1026 36.9213 54.5241 36.9213 56.2702V57.7407H27.0098L27.0095 56.2702Z"
      fill="#2C444D"
    />
    <path
      d="M7.11819 30.4088H7.91209C10.2775 30.4088 12.2036 28.4827 12.2036 26.1173V24.5049C12.2036 22.1395 10.2775 20.2134 7.91209 20.2134H7.11819C4.7528 20.2134 2.82666 22.1395 2.82666 24.5049V26.1173C2.82666 28.4854 4.7528 30.4088 7.11819 30.4088ZM4.92196 24.5076C4.92196 23.2963 5.90689 22.3114 7.11819 22.3114H7.91209C9.12346 22.3114 10.1083 23.2963 10.1083 24.5076V26.12C10.1083 27.3314 9.12339 28.3163 7.91209 28.3163H7.11819C5.90682 28.3163 4.92196 27.3313 4.92196 26.12V24.5076Z"
      fill="#2C444D"
    />
    <path
      d="M14.569 36.0563C14.569 33.1535 12.209 30.7935 9.30614 30.7935H5.72939C2.82656 30.7935 0.466553 33.1534 0.466553 36.0563V39.6221H14.5686L14.5659 36.0563H14.569ZM12.4737 37.5241H2.5622V36.0536C2.5622 34.3075 3.98365 32.886 5.72974 32.886H9.30649C11.0526 32.886 12.474 34.3075 12.474 36.0536L12.4737 37.5241Z"
      fill="#2C444D"
    />
    <path
      d="M56.8159 20.2158H56.0193C53.6539 20.2158 51.7278 22.142 51.7278 24.5073V26.1197C51.7278 28.4851 53.6539 30.4113 56.0193 30.4113H56.8159C59.1813 30.4113 61.1075 28.4851 61.1075 26.1197V24.5073C61.1075 22.1392 59.1813 20.2158 56.8159 20.2158ZM59.0122 26.117C59.0122 27.3284 58.0272 28.3132 56.8159 28.3132H56.0193C54.8079 28.3132 53.8231 27.3283 53.8231 26.117V24.5046C53.8231 23.2933 54.808 22.3084 56.0193 22.3084H56.8159C58.0273 22.3084 59.0122 23.2933 59.0122 24.5046V26.117Z"
      fill="#2C444D"
    />
    <path
      d="M54.6305 30.7935C51.7277 30.7935 49.3677 33.1534 49.3677 36.0563V39.6221H63.4697V36.0563C63.4697 33.1535 61.1098 30.7935 58.2069 30.7935H54.6305ZM61.372 36.0563V37.5268L51.4605 37.5241V36.0536C51.4605 34.3075 52.882 32.886 54.6281 32.886H58.2048C59.9536 32.8888 61.3723 34.3075 61.3723 36.0563L61.372 36.0563Z"
      fill="#2C444D"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgTeam',
}
</script>
