<template>
  <footer class="site-footer">
    <div class="upper-footer">
      <!-- Split -->
      <section class="split flex-end site-max-width">
        <a
          href="/pdf/map-tremblant.pdf"
          target="_blank"
          rel="noopener noreferrer"
          class="left"
        >
          <img loading="lazy" width="631" height="255" src="@/assets/img/map-tremblant.webp" v-if="$route.meta.lang === 'fr'" alt="Map directions pour Tremblant" />
          <img loading="lazy" width="631" height="255" src="@/assets/img/map-tremblant-en.webp" v-if="$route.meta.lang === 'en'" alt="Map directions pour Tremblant" />
        </a>
        <div class="right">
          <h3 class="regular-title">{{ $t('contact_title') }}</h3>
          <address class="row">
            <svg width="31" height="43" viewBox="0 0 31 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.7494 20.9216C12.4587 20.9216 9.78935 18.2543 9.78935 14.9616C9.78935 11.6688 12.4585 8.99966 15.7494 8.99966C19.0419 8.99966 21.7113 11.6688 21.7113 14.9616C21.7113 18.2544 19.0422 20.9216 15.7494 20.9216ZM29.9147 9.98352C29.6515 9.23877 29.3286 8.51457 28.9497 7.82209C28.5708 7.12775 28.1378 6.46323 27.6544 5.83612C27.1709 5.21083 26.639 4.62286 26.0641 4.0797C25.4874 3.53469 24.8677 3.03444 24.2125 2.58648C23.5517 2.13478 22.8536 1.73722 22.1294 1.39748C21.394 1.05404 20.6306 0.770328 19.8485 0.551958C19.0478 0.326106 18.2284 0.171183 17.4015 0.0853261C16.4216 -0.0173315 15.4211 -0.0266685 14.4393 0.0535945C13.6087 0.120787 12.7837 0.258914 11.9774 0.464235C11.1897 0.663954 10.4207 0.930867 9.67596 1.2594C8.9443 1.58231 8.23873 1.9631 7.56862 2.39984C6.90414 2.83101 6.27512 3.31632 5.68529 3.84825C5.10104 4.37649 4.55602 4.95324 4.05956 5.56735C3.56304 6.17955 3.11694 6.83284 2.72124 7.516C2.3274 8.20288 1.98581 8.91963 1.70398 9.66066C1.66292 9.76705 1.62372 9.87531 1.58639 9.98356C0.70914 12.4623 0.481404 15.2134 1.09738 18.0097C2.54579 24.6043 7.99633 29.7224 11.322 35.4153C11.617 35.9192 14.3571 40.315 15.35 42.5808V42.5827C15.4135 42.7432 15.5684 42.8571 15.7495 42.8571C15.9324 42.8571 16.0873 42.7432 16.1508 42.5827V42.5808C17.1438 40.3149 19.8838 35.9194 20.1788 35.4153C23.5049 29.7224 28.9551 24.6043 30.4034 18.0097C31.0175 15.2136 30.7917 12.4626 29.9144 9.98356"
                fill="#2C444D"
              />
            </svg>
            <a
              href="https://www.google.com/maps/dir//1000+Chem.+des+Voyageurs,+Mont-Tremblant,+QC+J8E+1T1/@46.20984,-74.6203107,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4ccf77d2317360bf:0x873fcc4b204881a3!2m2!1d-74.5852914!2d46.2098453!3e0" target="_blank"
              >1000 chemin des Voyageurs, Mont-Tremblant, <br />
              QC J8E 1T1, Canada</a
            >
          </address>
          <div class="row">
            <svg width="26" height="42" viewBox="0 0 26 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.50999 0C2.44167 0 0.75 1.6666 0.75 3.70426V37.9624C0.75 40.0001 2.44167 41.6667 4.50999 41.6667H21.99C24.0583 41.6667 25.75 40.0001 25.75 37.9624V3.70426C25.75 1.6666 24.0583 0 21.99 0L4.50999 0ZM11.1561 3.56756H15.3473C15.6232 3.56756 15.8462 3.87842 15.8462 4.26253C15.8462 4.64664 15.6232 4.9575 15.3473 4.9575H11.1561C10.8802 4.9575 10.6572 4.64664 10.6572 4.26253C10.6572 3.87842 10.8802 3.56756 11.1561 3.56756ZM2.81663 8.5186H23.6868V33.1463H2.81663V8.5186ZM13.2508 35.1125C14.5361 35.1125 15.5785 36.1394 15.5785 37.4057C15.5785 38.6719 14.5361 39.6989 13.2508 39.6989C11.9656 39.6989 10.9232 38.6719 10.9232 37.4057C10.9232 36.1394 11.9656 35.1125 13.2508 35.1125Z"
                fill="#2C444D"
              />
            </svg>
            <a href="tel:18662146940">1-866-214-6940</a>
          </div>
          <a class="map" target="_blank" href="https://www.google.com/maps/dir//1000+Chem.+des+Voyageurs,+Mont-Tremblant,+QC+J8E+1T1/@46.20984,-74.6203107,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4ccf77d2317360bf:0x873fcc4b204881a3!2m2!1d-74.5852914!2d46.2098453!3e0">
            <img loading="lazy" width="648" height="504" src="@/assets/img/map.webp" alt="Map directions pour Tremblant" />
          </a>
        </div>
      </section>
    </div>
    <!-- Legals -->
    <div class="legals site-max-width">
      <div class="left-wrap">
        <p class="small-text">
          {{ $t('copy') }} |
          <a
            :href="$t('politique_link')"
            target="_blank"
            >{{ $t('politique') }}</a
          >
          |
          <a
            :href="$t('condition_link')"
            target="_blank"
            >{{ $t('condition') }}</a
          >
          |
        </p>
        <button id="ot-sdk-btn" class="ot-sdk-show-settings small-text">{{ $t("Vos choix de confidentialité") }}</button>
      </div>
      <div class="socials">
        <a href="https://www.linkedin.com/company/association-de-vill%C3%A9giature-tremblant/" target="_blank">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM7.20093 12.4238H10.6003V22.6374H7.20093V12.4238ZM10.8243 9.26419C10.8022 8.26275 10.0861 7.5 8.92318 7.5C7.76029 7.5 7.00003 8.26275 7.00003 9.26419C7.00003 10.2449 7.73782 11.0296 8.87905 11.0296H8.90077C10.0861 11.0296 10.8243 10.2449 10.8243 9.26419ZM18.9457 12.1836C21.1826 12.1836 22.8596 13.6437 22.8596 16.7808L22.8595 22.637H19.4603V17.1727C19.4603 15.8002 18.9684 14.8637 17.7378 14.8637C16.7987 14.8637 16.2393 15.495 15.9936 16.1049C15.9037 16.3234 15.8817 16.6279 15.8817 16.9332V22.6373H12.4819C12.4819 22.6373 12.5267 13.3821 12.4819 12.4237H15.8817V13.8704C16.3328 13.1749 17.1409 12.1836 18.9457 12.1836Z"
              fill="white"
            />
          </svg>
        </a>
        <a href="https://www.facebook.com/tremblant" target="_blank">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM16.5634 15.6589V23.8197H13.1869V15.6592H11.4999V12.8469H13.1869V11.1585C13.1869 8.86425 14.1394 7.5 16.8457 7.5H19.0987V10.3126H17.6904C16.6369 10.3126 16.5672 10.7056 16.5672 11.4391L16.5634 12.8466H19.1147L18.8161 15.6589H16.5634Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
    </div>
    <!-- Alert -->
    <!-- <div class="alert-footer-bar site-max-width">
      <p class="small-text">
        Veuillez noter que certains contenus présentés sur ce site ne représentent pas l'expérience avec les mesures sanitaires en place. Merci de les
        <a
          href="https://www.tremblant.ca/montagne-village/mesures-sanitaires?sc_lang=fr&_gl=1*9tjpz5*_ga*ODU4MDI0MzAzLjE1ODUwNjQyNDc.*_ga_B1YVPN56M6*MTY0MTgxODM1NC42MC4xLjE2NDE4MjA0NTMuMA..&_ga=2.56059688.2042242952.1641809042-858024303.1585064247"
          target="_blank"
          >consulter</a
        >
        avant votre venue.
      </p>
    </div> -->
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
